.notifications {
  padding: 1em;
  height: 100%;
  width: 100%;
  overflow: auto;

  .btn {
    margin: 0 auto;
    background-color: #70ad47;
    color: white;
    border-radius: 0px;
    font-size: 12px;
    font-weight: 500;
  }

  .ant-pagination {
    .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .button {
    margin: 0px 2px;
    // padding: 4px 4px;
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    &:disabled {
      background-color: #ddd;
      pointer-events: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.modal-dialog.notifications-form {
  width: 60vw;

  @media screen and (max-width: 990.98px) {
    width: 80vw;
  }

  @media screen and (max-width: 575.98px) {
    width: 90vw;
  }
}
