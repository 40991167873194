.pareto-list {
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
  

  .vtable-cell {
    border: 0px;
    justify-content: center;
  }
}

.pareto-list__title {
  padding-left: 8px;
  font-weight: 500;
  z-index: 3;
}

.pareto-list-item {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.pareto-list-item__tracking,
.pareto-list-item__button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pareto-list-item__tracking {
  .anticon {
    font-size: 18px;
  }
}

.pareto-list-item__button {
  .anticon {
    font-size: 15px;
  }
}

.pareto-list-item__count {
  text-align: right;
  line-height: 40px;
  padding-right: 6px;
  font-size: 12px;
  width: 100%;
}

.pareto-list-item__bar-wrapper {
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
}

.pareto-list-item__bar {
  height: 80%;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  position: absolute;
  background: black;
  height: 100%;
}

.pareto-list-item__bar-label {
  text-align: right;
  width: 100%;
  position: absolute;
  mix-blend-mode: difference;
  color: white;
  z-index: 1;
  padding-right: 6px;
  padding-left: 6px;
  font-size: 12px;
}

.pareto-list-header {
  display: flex;
  width: 100%;
  position: relative;
  font-size: 12px;
}

.pareto-list-header__tracking {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pareto-list-header__count {
  display: flex;
  align-items: center;
}

.pareto-list-header__bar {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
