.searchable-tree {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.searchable-tree__search-input {
  flex-shrink: 0;
}

.searchable-tree__tree-container {
  flex-grow: 1;
  border: 1px solid #ddd;
  padding-top: 0.5em;
  border-top: 0px;
  max-height: 100%;
  overflow: auto;
  background: #fff;

  .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.highlight-search-word {
  font-weight: 500;
  padding: 0;
  background-color: transparent;
}
