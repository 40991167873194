.st-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  transform: translateY(100%);

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }

  .st-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: #f4f4f4;
    cursor: pointer;
    transition: opacity 300ms ease-out;

    &.mounted {
      opacity: 0.4;
    }
  }

  .st-modal-content {
    max-width: 540px;
    width: 100%;
    z-index: 100;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background: #ffffff;
    transform: translateY(100%);
    margin: 8px;
    transition: transform 300ms ease-in;

    &.mounted {
      transform: translateY(0);
    }

    .st-modal-content-header {
      & > .panel-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin: 15px 12px 15px 20px;
      }

      .content-header-title {
        flex: 1;
      }

      .content-header-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .actions-dismiss-timer {
          height: 25px;
          margin-right: 4px;
        }
      }
    }

    .st-modal-content-body {
      padding: 16px 16px 0 16px;
      margin-bottom: 8px;

      textarea {
        height: 80px;
        width: 100%;
        resize: none;
      }
    }

    .st-modal-content-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0 16px 16px 16px;
    }
  }
}
