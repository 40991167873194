.chart-v1 {
  position: relative;

  &.scatterplot {
    .axis {
      &.y-axis {
        .domain {
          display: block;
        }
      }
      .axis-label {
        fill: #616161;
        font-size: 11px;
      }
    }

    .overlay {
      fill: none;
      pointer-events: all;
      cursor: crosshair;
    }
  }

  &.pareto--container {
    width: 100%;

    .pareto--header {
      font-size: 12px;
    }
  }

  &.heatmap--container {
    .heatmap--x-axis {
      height: 30px;
      position: sticky;
      top: 0px;
      z-index: 1;
      background: #fff;
    }

    .heatmap--chart {
      position: relative;
    }
  }

  .chart-v1__title,
  .chart-v1__subtitle {
    font-size: 12px;
    font-weight: bolder;
    margin-bottom: -3px;
    margin-left: 34px;
  }

  .chart-aggregate {
    position: absolute;
    width: 100%;
    text-align: center;
  }

  // &.cumulative-chart {
  //   .chart-title {
  //     border-bottom: 2px solid hsl(0, 60%, 50%);
  //     margin-left: 20px;
  //     padding-bottom: 5px;
  //   }

  //   .chart-subtitle {
  //     position: absolute;
  //     right: 20px;
  //     padding-top: 5px;
  //     font-style: italic;
  //   }
  // }

  .grid {
    stroke-width: 0.25px;

    .domain {
      display: none;
    }
  }

  // .offline-indicator {
  //   font-size: 16px;
  //   font-weight: bold;
  //   fill: #616161;
  // }

  // .line {
  //   fill: none;
  //   stroke-width: 1.5px;
  // }

  // .dot {
  //   cursor: pointer;
  // }

  // .area {
  //   fill: hsl(0, 0%, 75%);
  // }

  // .grid {
  //   .domain {
  //     display: none;
  //   }

  //   line {
  //     shape-rendering: crispEdges;
  //     stroke: lightgray;
  //   }
  // }

  .axis {
    &.y-axis {
      .domain {
        display: none;
      }
    }

    .domain {
      stroke: #ddd;
    }

    line {
      shape-rendering: crispEdges;
      stroke: lightgray;
    }

    text {
      font-size: 9px;
    }
  }

  canvas {
    cursor: crosshair;
  }

  .brush {
    .overlay {
      fill: none;
      pointer-events: all;
    }
  }

  .line-focus {
    // fill: maroon;
    fill: none;
    stroke: maroon;
    r: 5px;
  }
}

// .legend {
//   position: relative;
//   top: auto;
//   left: 30px;
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;

//   @media screen and (max-width: 990.98px) {
//     position: relative;
//     top: auto;
//     left: 30px;
//     width: 100%;
//     display: flex;
//     flex-wrap: wrap;
//   }

//   .legend-keys {
//     font-size: 12px;
//     margin-bottom: 2px;
//     padding-left: 6px;
//     height: 16px;
//     line-height: 1;
//     overflow: hidden;
//     width: 25%;

//     .legend-color {
//       width: 15px;
//       height: 15px;
//       border-radius: 1px;
//       display: inline-block;
//     }

//     span {
//       margin-left: 10px;
//       margin-top: 1px;
//       vertical-align: text-top;
//     }
//   }

//   &.vertical {
//     position: absolute;
//     bottom: 20px;
//     right: 30px;
//     left: inherit;
//     width: 200px;

//     .legend-keys {
//       width: 100%;
//     }
//   }
// }

// .link {
//   fill: none;
//   stroke-opacity: 0.5;
// }

// .node {
//   opacity: 0.8;
// }

// .chart-labels {
//   position: relative;
//   cursor: pointer;
//   z-index: 2;

//   .bar-label-text {
//     font-size: 12px;
//     // fill: #fff;
//     font-weight: 200;
//     pointer-events: none;
//   }
// }

// .heatmap-container {
//   width: 100%;
//   max-height: 100%;

//   .heatmap {
//     max-width: 80%;
//     max-height: 100%;
//     overflow-y: hidden;
//     margin: 0 auto;
//     margin-top: 20px;

//     .y-container {
//       position: relative;

//       .y-axis {
//         z-index: 1;
//         position: absolute;
//         background-color: rgb(250, 250, 250);
//       }
//     }

//     .heatmap-svg {
//       display: block;
//       display: inline-block;
//     }

//     .oeeValue {
//       cursor: pointer;
//     }
//   }

//   .scale-container {
//     .heatmapScale {
//       width: 60%;
//       height: 20px;
//       margin: 0 auto;
//       margin-top: 20px;
//       background-image: linear-gradient(
//         to right,
//         rgba(204, 51, 51, 0.8),
//         rgba(255, 179, 0, 0.8),
//         rgba(64, 191, 106, 0.8)
//       );
//       display: flex;
//       justify-content: space-between;

//       label {
//         margin: 2px 10px;
//         font-weight: 400;
//         font-size: 12px;
//       }
//     }
//   }
// }

.circular-chart {
  // display: block;
  // margin: 10px auto;
  max-width: 100%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.percentage {
  // fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
  font-weight: bold;
}

.text-percentage {
  // fill: #666;
  font-family: sans-serif;
  font-size: 0.3em;
  text-anchor: middle;
  font-weight: bold;
}
