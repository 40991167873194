.issue-tracker-container {
  padding: 1em 2em;
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow: auto;

  .ant-steps-small .ant-steps-item-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tag {
    height: 20px;
  }

  .ant-avatar {
    margin-right: 0.5rem;
  }

  .danger-zone__item {
    border: 1px solid #dc3545 !important;
    border-radius: 2px;
  }

  hr {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.5rem;
  }

  .tracked-issues-vlist-container {
    flex-grow: 1;
  }

  .ant-pagination {
    .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-table-cell {
    ol {
      margin-bottom: 0px;
    }
  }

  .ant-table-row {
    &.highlighted {
      background: #9aceef6e;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    // padding: 10px 0px;

    .btn {
      margin: 0 auto;
      background-color: #70ad47;
      color: white;
      border-radius: 0px;
      font-size: 12px;
      font-weight: 500;
    }

    .button {
      margin: 0px 2px;
      border-radius: 50%;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;

      &:disabled {
        background-color: #ddd;
        pointer-events: none;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.treenode-title {
  display: flex;
  align-items: center;
}

.tracked-issue-editor {
  min-width: 700px;

  .ant-select-selector {
    padding: 0px;
  }
}

.ant-select-tree-list {
  .treenode-title {
    &.not-selectable {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.tracked-issue-list-item {
  &.selected {
    border: 1px solid #1890ff;
  }

  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 2px;

  .ant-steps-item-content {
    min-width: 180px;
    font-size: 12px;
  }

  .ant-steps-item-description {
    text-align: left;
    display: -webkit-box;
    width: 80% !important;
    max-width: 300px !important;
    height: 65px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
