.hardware-management {
  &.spa-wrapper {
    .ant-layout-sider-children {
      // border: 1px solid #ddd;
      border: 0px;
      border-right: 1px solid #ddd;
      padding-right: 1.5rem;
      background-color: #fff;
      border-radius: 0;
    }
  }
  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content-top {
    height: 100%;
    padding: 1rem;
  }

  .device-popover {
    &.no-warning {
      .ant-popover-inner-content {
        display: none;
      }
    }
  }

  .device-scan {
    margin: 0 auto;
    margin-top: 40px;

    .device-scan__icons {
      justify-content: center;
    }

    .ant-tabs-content-top {
      height: 100%;
    }
  }
}

.add-relay {
  .device-scan__icons {
    justify-content: center;
  }

  .device-scan {
    margin: 0 auto;
  }
}

.hardware-management__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.mesh-legend {
  position: absolute;
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.hardware-vlist-container {
  width: 100%;
  height: 100%;

  .list-item {
    cursor: pointer;
    padding: 0.5em 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 2px;
    height: 100%;

    &.selected {
      border: 3px solid rgba(0, 0, 0, 0.5);
      box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
    }

    .LeftSideGrid {
      overflow: hidden;
    }
  }

  .list-item__content {
    width: calc(100% - 98px);
  }

  .list_item__content__label {
    font-size: 13px;
  }
}
