.objective-page-header {
  display: flex;
  justify-content: space-between;
}

.objective-page-header__info__description {
  .MuiTypography-root:first-child {
    margin-right: 8px;
  }

  .MuiTypography-root:last-child {
    margin-left: 8px;
  }
}
