.block-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .danger-zone__item {
    border: 1px solid #dc3545 !important;
    border-radius: 2px;
  }
}

.block-form__fields {
  overflow: auto;
  margin-bottom: 0.5rem;
}

.block-form__submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
