.descriptive-input {
  flex-direction: row;
  margin-bottom: 4px;
  align-items: center;
}

.descriptive-input-field {
  height: 32px;

  .MuiOutlinedInput-input {
    padding: 6px 12px;
    font-size: 14px;
  }
}
