.tabs-wrapper {
  margin-top: 20px;

  .inactive {
    opacity: 0.5;
  }

  .tabs-header {
    display: flex;

    .tabs-header-item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background-color: #f1f1f3;
      margin: 1px;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 15px;
      font-weight: 300;
      color: back;

      &.active {
        background-color: rgba(32, 186, 184, 1);
        color: #ffffff;
        font-weight: 400;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .tabs-content {
    height: 450px;
    overflow: auto;
    margin: 4px 1px 1px 1px;
    border: 2px solid #f1f1f3;

    .configuration-panel--chart {
      margin-top: 10px;
      height: 40%;
      position: relative;
    }

    .configuration-panel-tab {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        font-weight: 500;
        position: absolute;
        height: 40px;
        width: 40px;
        top: 0px;
        right: 0px;
        font-size: 28px;
        z-index: 2;

        &.btn-success {
          background-color: #20bab8;
          border: none;
        }
      }

      .basic-view-panel-wrapper {
        flex-grow: 1;
        min-height: calc((100%) * 1 / 2);
        display: flex;
        justify-content: space-between;

        .panel-item-wrapper {
          position: relative;
          width: 32%;

          .basic-view-panel {
            pointer-events: none;
          }
        }
      }

      .operator-chart {
        pointer-events: none;
      }
    }

    .configuration-skus-tab {
      padding: 15px 15px 0px 15px;

      .table-responsive {
        table {
          margin-bottom: 0px;
          thead {
            tr {
              th {
                border-top: none;
              }
            }
          }
        }
      }
    }

    .configuration-alerts-tab {
      padding: 15px 15px 0px 15px;
      display: flex;
      flex-direction: column;

      .alert-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .react-select-container {
          width: 150px;
          margin: 0px 10px;

          &.oee-select {
            width: 200px;
          }

          .react-select__control {
            border: none;
            border-bottom: 1px solid #bbb;
            border-radius: 0;
          }

          .react-select__indicator-separator {
            display: none;
          }

          .react-select__dropdown-indicator {
            padding: 0px 6px;
          }
        }

        .form-control {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #bbb;
        }
      }

      .add-alert {
        background-color: #1fbab7;
        border: none;
        color: #ffffff;
        text-transform: uppercase;
        width: fit-content;
        align-self: flex-end;
        margin: 0;
      }
    }
  }
}

.asset--operator {
  position: relative;
  height: 100%;
  padding: 1em;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 50px);
  background: #fff;
}
.basic-view-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;

  .basic-view-panel-wrapper {
    flex-grow: 1;
    max-height: 60%;
    padding: 20px 0;

    .panel-wrapper {
      height: 100%;
    }
  }

  .basic-view-chart {
    height: 40%;
    width: 100%;
  }
}

.operator-chart {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .operator-chart__header {
    font-size: 12px;
    font-weight: 400;
    margin-left: 30px;
  }

  .operator-chart__main {
    width: 100%;
    flex-grow: 1;
  }

  .operator-chart__labels {
    height: 18px;
    margin-bottom: 8px;
    flex-shrink: 0;
  }
}
