// .tracked-issue-slider {
//   .slide-content-container {
//     height: 100%;
//     width: 100%;
//     display: grid;
//     display: -ms-grid;
//     -ms-grid-columns: 1fr 2fr;
//     grid-template-columns: 1fr 2fr;
//     -ms-grid-rows: auto;
//     grid-template-rows: auto;
//     grid-template-areas:
//       "first second";

//     .first {
//       -ms-grid-row: 1;
//       -ms-grid-column: 1;
//       border-right: 1px solid #ddd;
//       grid-area: first;
//       overflow: auto;
//     }

//     .second {
//       -ms-grid-row: 1;
//       -ms-grid-column: 2;
//       overflow: auto;
//       grid-area: second;

//       .comment-row {
//         display: grid;
//         display: -ms-grid;
//         -ms-grid-columns: 50px 1fr;
//         grid-template-columns: 50px 1fr;
//         -ms-grid-rows: 1fr;
//         grid-template-rows: 1fr;
//         grid-template-areas:
//           "userImage activity";

//         .image {
//           -ms-grid-row: 1;
//           -ms-grid-column: 1;
//           grid-area: "userImage";
//         }

//         .activity {
//           -ms-grid-row: 1;
//           -ms-grid-column: 2;
//           grid-area: "activity";
//         }
//       }
//     }
//   }
// }

.tracked-issue-drawer {
  .ant-btn {
    margin-right: 4px;
    margin-left: 4px;
  }

  .ant-drawer-body {
    padding: 0 24px;
    padding-bottom: 10px;
    // font-size: 12px;
  }

  .slide-content-container {
    height: 100%;
    width: 100%;

    .comment-row {
      .image {
        flex-shrink: 0;
        width: 50px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid #ddd;
        }
      }

      .comment {
        flex-grow: 1;
      }
    }
  }
}

.tracked-issue-drawer__header {
  position: sticky;
  top: 0px;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 28, 85, 1);
  background: #fff;
  width: 90%;
  margin: 0 auto;
}
