.process-tree-table {
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;

  .tree-table-container {
    width: auto !important;
    .process-name-header {
      padding-left: 48px;
    }
  }
}
