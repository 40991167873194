.summary-tile-edit {
  display: flex;
  flex-direction: column;

  .panel {
    .panel-body {
      overflow: auto;
      padding: 1em 2em;
      max-width: 80vw !important;
    }
  }
}
