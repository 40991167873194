.user-config {
  padding: 1em;

  .panel {
    max-width: 100%;
    max-height: 100%;
  }

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content {
    display: flex;
    justify-content: center;
  }

  .ant-tabs-content-top {
    height: 100%;
    padding: 1rem;
    overflow: auto;
  }

  .ant-form-item.no-style {
    .ant-form-item-label {
      label {
        border: none;
        background: #fff;
      }
    }
  }
}

.profile-form {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1em !important;
  flex-direction: column;
}

.profile-form__image {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.profile-avatar {
  position: relative;
  cursor: pointer;

  .profile-avatar-image {
    position: relative;
    width: 210px;
    height: 210px;
    background: rgb(219, 219, 219);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  }
}

.user-preference-form {
  width: 100%;
  height: 100%;
  padding: 1em !important;
  align-items: center;
}

.edit-row {
  width: 100%;
  padding: 1em;
  display: flex;
  border-bottom: 1px solid #ddd;
}

.edit-row__label {
  width: 50%;
  font-weight: 500;
}

.edit-row__value {
  flex-grow: 1;
  text-align: right;
}

.edit-row__icon {
  flex-shrink: 0;
  padding-right: 1em;
  padding-left: 1em;
  display: flex;
  align-items: center;
}

.anticon-arrow-right {
  cursor: pointer;
}
