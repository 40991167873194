.asset-events {
  padding: 1em;

  .events-label-notes-input {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.asset-events__header {
  display: flex;
}

.asset-events-filter {
  display: flex;

  .ant-row {
    flex-direction: column;
  }

  .ant-form-item {
      margin-right: 1em;
  }

  .ant-form-item-label {
      text-align: left;
      height: 28px;
      font-weight: 500;
      
      label {
          color: #616161;
          font-size: 13px;
      }
  }

  .ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
      padding-right: 0px;
  }
}

.asset-events-filter__datepicker {
  display: flex;
  align-items: center;
  
  .auk-button.ant-btn-icon-only {
    width: 20px;
  }
}

.asset-events__body {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

.asset-events__body__list-wrapper {
  padding-top: 3em;
  height: 100%;
  width: calc(100% - 10px);
}

.asset-event-list-header {
  display: flex;
  height: 30px;
}

.asset-event-list-item {
  display: flex;
  height: 100%;
}

.asset-event-item-col {
  display: flex;
  align-items: center;
  padding: 0px 6px;
}

.asset-event-item-col--1 {
  width: calc(100% / 12 * 1);
}

.asset-event-item-col--2 {
  width: calc(100% / 12 * 2);
}

.asset-event-item-col--4 {
  width: calc(100% / 12 * 4);
}

