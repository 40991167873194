.language-select__body {
    display: flex;
    flex-wrap: wrap;
}

.language-select__item {
    width: 25%;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.selected {
        font-weight: 600;
    }

    &:hover {
        cursor: pointer;
        font-weight: bold;
    }
}

.language {
    position: absolute !important;
    top: 32px;
    right: 32px;
    z-index: 2;
}
