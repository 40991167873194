.report-summary-table {
    opacity: 1;
    margin-bottom: 1em;

    th {
        text-transform: uppercase;
    }

    .ant-table-thead > tr > th {
        background: none;
        text-align: center;
    }

    tbody td {
        color: #1890FF;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    .ant-table-summary td {
        font-weight: 600;
        text-align: center;
    }
}