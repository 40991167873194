.month-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0 !important;

  &:first-child .month-button-container {
    border-radius: 18px 0 0 18px;
  }

  &:last-child .month-button-container {
    border-radius: 0 18px 18px 0;
  }

  .month-button-container {
    flex: 1;

    &.start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &.end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    &.highlight {
      $color: var(--primary);
      background: $color;
      background: rgba(32, 100, 135, 0.1) !important;
    }
  }

  &:not(:last-child):not(:first-child) > .month-button-container {
    padding: 0 8px;

    &.start {
      padding-left: 0 !important;
      margin-left: 8px !important;
    }

    &.end {
      padding-right: 0 !important;
      margin-right: 8px !important;
    }
  }

  .month-button-btn {
    border-radius: 18px !important;
    text-transform: none !important;
    color: #000000 !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.75 !important;
    letter-spacing: 0.00938em !important;
    padding: 4px 8px !important;
    margin: 0 !important;
    border: 0 !important;
    outline: 0 !important;

    &.active {
      background: var(--primary) !important;
      color: #FFFFFF !important;
    }
  }
}
