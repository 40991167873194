.MuiFormControl-root.impact-form-field-wrapper {
    margin-bottom: 20px;

    .checkbox-control-label {
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-left: 13px;
        margin-right: 0;
    }
}

