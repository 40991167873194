.page {
    height: 100%;
    width: 100%;
    padding: 1em;
    overflow: hidden;
    position: relative;
    background-color: #fff;
}

.page-title {
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 14px;
    }
}
