// .ant-carousel {
//     height: 100%;

//     .slick-slider {
//         height: 100%;
//     }

//     .slick-list { 
//         height: 100%;
//     }

//     .slick-track {
//         height: 100%;
//     }

//     .slick-slide {
//         height: calc(100% - 1em);
//         display: flex !important;
//     }

//     .slick-dots li button {
//         background: #1490FF !important;

//         &:before {
//             opacity: 0;
//         }
//     }
// }

// .ant-carousel .slick-dots {
//     margin-right: 0px !important;
//     margin-left: 0px !important;
//     margin-bottom: 1em !important;
// }

// .ant-carousel .slick-list {
//     padding: 0px 0px 1em 0px !important;
// }

.carousel-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 360px;
    overflow: hidden;
    padding: 1em;

    .ant-table-thead {
        .ant-table-cell {
            font-weight: 400;
        }
    }

    .ant-table-cell {
        font-size: 11px;
    }

    .vtable-cell {
        font-size: 11px;
    }
}