.collapsible {
  position: relative;

  .collapsible--header {
    position: absolute;
    z-index: 2;
    display: flex;

    &.collapsed {
      border: 1px solid #ddd;
      position: relative;
    }

    span {
      padding: 0px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
