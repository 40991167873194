.danger-zone-container {
  .ant-collapse-header {
    color: rgba(214, 6, 6, 1) !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    border-bottom: 1px solid #ddd !important;
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.danger-zone {
  border: 1px solid #dc3545;
  border-radius: 3px;
}

.danger-zone__header {
  color: #dc3545;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.25em;
}

.danger-zone__item {
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: 0px;
  }
}

.danger-zone__item__body__title {
  font-size: 12px;
  font-weight: 500;
}

.danger-zone__item__body__description {
  font-size: 11px;
}

.danger-zone__item__button {
  .auk-button {
    font-size: 12px;
    width: 70px;
  }
}
