.spa-wrapper {
  position: relative;
  height: 100%;
  padding: 1em;
  width: 100%;
  overflow: hidden;
  background: #fff !important;

  .ant-layout {
    background: #fff;
  }

  .ant-layout-sider {
    border-radius: 2px;

    .ant-layout-sider-trigger {
      position: relative;
      background: #001c55;
      color: #fff;
      width: 100% !important;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-layout-sider-children {
      height: 0px;
      flex-grow: 1;
      border: 1px solid #001c55;
      border-radius: 2px;
    }

    &.ant-layout-sider-collapsed {
      .ant-layout-sider-children {
        .child {
          display: none !important;
        }
      }
    }
  }

  .ant-layout-sider-has-trigger {
    padding-bottom: 0;
  }

  .ant-layout-sider-light {
    background: #fafafa;
    .ant-layout-sider-zero-width-trigger {
      top: 0px;
      background: #001c55;
      color: #fff;
    }
  }
}
