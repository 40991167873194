.opcua-dialog {
    width: 80vw;

    .panel {
        max-width: 80vw;
    }
}

.opcua-form {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    .ant-input-group-addon {
        min-width: 60px;
        text-align: left;
    }

    .opcua-form-item {
        border-radius: 4px;
    }

    .opcua-form-item:nth-child(odd) {
        background: #f0f2f5;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0px !important;
    }

    .ant-row {
        width: 100%;
    }

    .ant-form-item.opcua-form-row-item {
        padding: 0px 6px;
    }

    .ant-collapse-item:not(.ant-collapse-item-active) .ant-collapse-header {
        border: 0px;
    }
}

.opcua-form-row {
    display: flex;
    padding: 1em 0.5em;
}

.opcua-form-row-item {
    padding: 0px 6px;
}

.opcua-form-row-item--5 {
    width: 5%;
    flex-shrink: 0;
}

.opcua-form-row-item--10 {
    width: 10%;
    flex-shrink: 0;
}

.opcua-form-row-item--15 {
    width: 15%;
    flex-shrink: 0;
}

.opcua-form-row-item--20 {
    width: 20%;
    flex-shrink: 0;
}

.opcua-form-row-item--25 {
    width: 25%;
    flex-shrink: 0;
}