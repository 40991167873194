.page_permission {
  padding: 1em;
  overflow-x: hidden;

  > div {
    > .auk-modal-wrapper {
      top: 100px;

      &.auk-confirmation-modal {
        > .auk-modal {
          min-height: unset;

          > .auk-modal-body {
            min-height: unset;
          }
        }
      }

      > .auk-modal {
        max-width: unset;
        width: 70vw;

        > .auk-modal-footer {
          .auk-btn-primary {
            background-color: #268bff;
          }
        }
      }
    }
  }

  .permission-policies-table {
    width: 100%;
    .no-policy-data-body {
      position: relative;

      > .no-policy-data-text {
        margin: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 50%);

        > .create-policy-btn {
          font-weight: 600;

          &:hover {
            cursor: pointer;
          }
        }
      }

      > .no-policy-data {
        height: 50px;

        > .policy-name {
          border-left: none;
          border-right: none;

          &:first-child {
            border-left: 1px solid #dee2e6;
          }

          &:last-child {
            border-right: 1px solid #dee2e6;
          }
        }
      }
    }

    .policy-name {
      width: 200px;
    }

    .policy-name,
    .policy-block-access,
    .users-list,
    .groups-list,
    .actions {
      // vertical-align: middle;
      background-color: #ffffff;

      > .data {
        height: 100%;
        // max-height: 200px;
        overflow-y: scroll;
      }
    }

    .actions {
      text-align: center;
      width: 100px;

      > .data {
        text-align: center;

        > span {
          margin-right: 10px;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .policy-block-access {
      width: 400px;

      > .data {
        position: relative;

        > .permission-btn-action-block {
          position: absolute;
          top: 2px;
          right: 0px;
          z-index: 2;

          > .save-btn,
          .cancel-btn {
            display: inline;

            border: 1px solid #ccc;
            padding: 2px;
            padding-left: 10px;
            padding-right: 10px;

            &:hover {
              cursor: pointer;
            }
          }

          > .save-btn {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.permission-create-policy {
  > .create-policy-item {
    margin-bottom: 20px;

    .create-policy-input {
      margin-top: 10px;
      margin-bottom: 10px;

      &.resources {
        margin-top: 20px;
      }
    }

    .policy-item-label {
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
    }

    .resource-item {
      margin-bottom: 10px;

      .custom-selection-resources {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .resource-checker {
        margin-right: 5px;
        text-transform: capitalize;
        display: inline-block;
        // width: 50px;
        margin-bottom: 0;

        span {
          margin-right: 2px;
          text-transform: capitalize;

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
