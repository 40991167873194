.report-filter {
    display: flex;

    .ant-row {
        flex-direction: column;
    }

    .ant-form-item {
        margin-right: 1em;
    }

    .ant-form-item-label {
        text-align: left;
        height: 28px;
        font-weight: 500;
        
        label {
            color: #616161;
            font-size: 13px;
        }
    }

    .ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
        padding-right: 0px;
    }
}