.shopfloor-dashboard {
  padding: 0px;
}

.shopfloor-dashboard__header {
  padding: 1em;
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.shopfloor-dashboard__filter {
  width: 280px;
}

.shopfloor-dashboard__body {
  flex-grow: 1;
  width: 100%;
}

.shopfloor-asset-card {
  cursor: pointer;
  width: 276px;
  height: 120px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 14px 26px;
  justify-content: space-between;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.shopfloor-asset-card--with-transparency {
  opacity: 0.6;
  background: #eee;
}

.shopfloor-asset-card--disabled {
  pointer-events: none;
}

.shopfloor-asset-card__header {
  width: 100%;
  height: 20px;
  display: flex;
}

.shopfloor-asset-card__label {
  width: calc(100% - 18px);
  height: 20px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 17px;
  font-family: 'Segoe UI', sans-serif;
  line-height: 23px;
  color: #000000D9;
}

.shopfloor-asset-card__body {
  width: 100%;
  overflow: hidden;
  position: relative;
  // height: 28px;
}

.shopfloor-asset-card__bar {
  height: 20px;
  display: flex;
  flex-direction: column-reverse;

  .percent-bar-container {
    border-radius: 12px;
    height: 10px !important;
    overflow: hidden;
  }
}

.shopfloor-asset-card__footer {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 19px;
  color: #000000D9;
}

.shopfloor-asset-card__footer__link {
  color: #1890FF;
}

.shopfloor-line-card {
  width: 100%;
  height: 100%;
  padding-bottom: 8px;
  padding-left: 20px;
}

.shopfloor-line-card__label {
  font-weight: 700;
  display: flex;
  width: 100%;
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.shopfloor-line-card__content {
  display: flex;
  flex-wrap: wrap;
}

.target-actual__indicator-container {
  height: 10px;
  width: 100%;
  display: flex;
}
