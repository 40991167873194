.auk-checkbox {
  cursor: pointer;
  width: 100%;
  height: 100%;
  // border-radius: 1px;
  // border: 0.5px solid #ddd;

  &.indeterminate {
    opacity: 0.4;
    background-color: #4682b4;
  }

  &.checked {
    opacity: 1;
    background-color: #4682b4;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.auk-checkbox--indeterminate {
  opacity: 0.4;
  background-color: #4682b4;
}

.auk-checkbox--checked {
  opacity: 1;
  background-color: #4682b4;
}

.auk-checkbox--disabled {
  cursor: not-allowed;
}
