.export-overlay {
    position: relative;

    &:hover {
        .export-overlay-sticky {
            opacity: 1;
        }
    }
}

.export-overlay-sticky {
    position: sticky;
    opacity: 0;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 0.5em;
    z-index: 1;
    top: 0.5em;

    .auk-button {
        font-size: 10px;
        z-index: 1;
    }
}

.export-overlay-content {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}