.regression-analysis {
  padding: 10px;

  h6 {
    font-size: 14px;
  }

  .regression--controls {
    width: 360px;
    height: 100%;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 2px;
    overflow: auto;

    .ant-picker-range {
      width: 100%;

      .ant-picker-separator {
        display: flex;
      }

      .ant-picker-suffix {
        display: flex;
      }
    }

    button:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    .b-cancel {
      border: 1px solid #bbb;
    }

    .nav-link {
      padding: 4px 8px;
      font-size: 12px;
    }

    // .css-10nd86i {
    //   font-size: 12px;

    //   .css-1hwfws3 {
    //     padding: 0px 8px;
    //   }

    //   .css-fyho0h {
    //     padding: 0px 6px;
    //   }
    // }

    // .ant-calendar-picker-input {
    //   height: 25px;
    //   width: 100%;
    //   border-radius: 2px;
    // }

    .axis-translate {
      background-color: #fff;
      border-radius: 2px;
      margin: 0px;
      padding: 2px 10px;
      display: flex;
      align-items: center;

      .form-group {
        margin-bottom: 0px;

        &.axis-translate--input {
          display: flex;
          align-items: center;
          margin: 0px;

          .col,
          .col-2,
          .col-10 {
            padding: 0px;
          }

          .col-form-label {
            padding: 0px;
            font-size: 12px;
          }

          .form-control {
            height: 25px;
            border: none;
            border-bottom: 1px solid #bbb;
            width: 100%;
            padding: 0px;
            padding-left: 3px;
            padding-right: 3px;
          }

          input[type='number']::-webkit-inner-spin-button {
            opacity: 1;
            cursor: pointer;
          }
        }
      }
    }

    .regression--options {
      display: flex;
      flex-direction: column;

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  .color-legend-wrapper {
    position: absolute;
    font-size: 11px;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .trendline-eqn {
    font-size: 12px;
    position: absolute;
    box-shadow: 0px 1px 5px -3px rgba(0, 0, 0, 0.75);
    padding: 2px 1em;
  }

  .list-analyses {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 220px;

    .ant-list {
      height: 100%;
      border: 1px solid #ddd;
      border-radius: 2px;
      padding: 0 8px;
      background: #fafafa;

      .ant-list-header {
        font-size: 14px;
        // text-transform: uppercase;
        font-weight: 300;
      }

      .list-item {
        display: flex;
        justify-content: space-between;
        padding: 0.5em 1em;
        margin-top: 1em;
        margin-bottom: 1em;
        border-radius: 1px;
        background-color: #fff;
        cursor: pointer;
        box-shadow: 0px 1px 5px -3px rgba(0, 0, 0, 0.75);
        color: #222;

        &:hover {
          box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.75);
        }

        &.active {
          box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.75);
          font-weight: 350;
          text-transform: uppercase;
        }
      }
    }
  }
}
