.company-logo,
.content-comp-logo {
  border-radius: 4px;
}

.company-logo {
  overflow: hidden;
  z-index: 2;
  margin-right: 10px;
  background-color: white;
  cursor: pointer;

  .content-comp-logo {
    width: 40px;
    height: 40px;
    background-color: rgb(255, 255, 255);
  }
}