.blocks-navigator--container {
  .css-f91fgu {
    background: #fff;
  }

  .blocks-navigator__treebeard-header {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 12px;
    color: #222;
    margin-left: 4px;
  }

  .blocks-navigator__treebeard-toggle {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: #616161;
    opacity: 0.7;
    background-color: white;

    .blocks-navigator__treebeard-toggle__icon {
      width: 16px;
      text-align: center;
      cursor: pointer;
    }
  }
}
