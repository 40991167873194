.chart-v1__tooltip {
  position: absolute;
  background-color: #000;
  border-radius: 3px;
  padding: 8px 16px;
  color: #fff;
  font-size: 12px;
  visibility: hidden;
  z-index: 900;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
    }
  }

  .tooltip--pareto {
    width: 320px;
  }

  .tooltip--targets {
    width: 200px;
    .tooltip--targets__title {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0.5em;
    }

    .tooltip--targets__row {
      display: flex;
      .row-label {
        width: 50px;
        flex-shrink: 0;
      }
    }
  }
}
