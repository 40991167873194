.issue-management {
  display: flex;
  flex-direction: column;
  position: relative;

  .issue-management__header {
    display: flex;
    width: 100%;
  }

  .ant-tree {
    background: #fafafa;
  }

  // .ant-layout {
  //   background: #fafafa;
  // }

  // .ant-layout-sider {
  //   border-radius: 2px;

  //   .ant-layout-sider-trigger {
  //     position: relative;
  //     background: #001c55;
  //     color: #fff;
  //     width: 100% !important;
  //     height: 40px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }

  //   .ant-layout-sider-children {
  //     height: 0px;
  //     flex-grow: 1;
  //     border: 1px solid #001c55;
  //   }

  //   &.ant-layout-sider-collapsed {
  //     .ant-layout-sider-children {
  //       .child {
  //         display: none;
  //       }
  //     }
  //   }
  // }

  // .ant-layout-sider-has-trigger {
  //   padding-bottom: 0;
  // }

  // .ant-layout-sider-light {
  //   background: #fafafa;
  //   .ant-layout-sider-zero-width-trigger {
  //     top: 0px;
  //     background: #001c55;
  //     color: #fff;
  //   }
  // }

  .issue-management__v-table {
    position: relative;
    height: 100%;
    background: #fff;

    .LeftSideGrid {
      // background-color: #fafafa;
      overflow: auto !important;

      &::-webkit-scrollbar-track:vertical {
        display: none;
      }
      &::-webkit-scrollbar-thumb:vertical {
        display: none;
      }

      .ReactVirtualized__Grid__innerScrollContainer {
        overflow: initial !important;
      }
    }

    .HeaderGrid {
      transform: rotateX(180deg);
      // background-color: #fafafa;

      .ReactVirtualized__Grid__innerScrollContainer {
        max-height: 200px !important;
        overflow: auto !important;
        display: flex;
        justify-content: flex-start;
        flex-direction: column-reverse;
      }
    }

    .leftCell {
      align-items: flex-start;
    }

    .headerCell {
      display: flex;
      justify-content: flex-start;
    }

    .cell {
      border: 1px solid #ddd;
      padding: 2px;

      .checkbox-container {
        width: 100%;
        height: 100%;

        &.highlightable {
          &:hover {
            outline: 2px solid #616161;
          }
        }
      }
    }
  }
}
