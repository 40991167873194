.auk-logo-large {
    text-align: center;
  
    img {
      height: 5em;
    }
  }

  .layout-auth {
    width: 100%;
    height: 100%;
  }
  
  .layout-unauth__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .layout-auth__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
  }
  
  .spinner {
    position: fixed;
    font-size: 20px;
    left: 50%;
    top: 20px;
    color: white;
  }