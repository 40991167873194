.loading {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading__spinner {
  position: relative;
}

.loading__description {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}
