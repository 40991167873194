.fusion-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .ant-input-group-addon {
    min-width: 60px;
    text-align: left;
  }

  .danger-zone__item {
    border: 1px solid #dc3545 !important;
    border-radius: 2px;
  }
}

.fusion-form__fields {
  padding-top: 1rem;
  flex-grow: 1;
  overflow: auto;
}

.fusion-form__submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
