.page.impact-page {
    padding-top: 40px;
    padding-bottom: 0;
    font-family: Roboto, sans-serif;
    font-weight: 400;

    @media screen and (max-width: 1440px) {
        overflow: auto;
    }

    @media screen and (max-width: 1024px) {
        padding-top: 30px;
    }
}

.impact-chart-tooltip {
    display: flex;
    flex-direction: column;
}

.impact-chart-tooltip__title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.impact-chart-tooltip__content {
    letter-spacing: 0.25px;
    font-size: 14px;
    line-height: 20px;
}

.auk-toggle-buttons.impact-toggle-buttons {
    @media screen and (max-width: 1024px) {
        width: 233px;
        margin-bottom: 0px;
    }
}

.impact-page-form-wrapper{
    flex-grow: 1;
    overflow: auto;
    padding-top: 8px;
}
