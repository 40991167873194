.month-calendar {
  user-select: none;
  padding: 16px;

  .month-calendar-actions {
    padding-bottom: 8px;

    .calendar-actions-year {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .calendar-actions-item {
      width: 40px;
      height: 40px;
    }
  }
}
