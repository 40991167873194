.auk-drawer-labeller {
  .auk-drawer__close {
    color: #fff;
  }
}

.labeller {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    padding-left: 11px;
    padding-right: 0px;
    max-width: 153px;
    font-size: 13px;
  }

  .barcode-wrapper {
    margin-bottom: 0.25em;
  }
}

.labeller-header {
  background: rgba(0, 28, 85, 1);
  color: #fff;
  flex-shrink: 0;
  box-shadow: 1px 3px 12px -4px rgba(0, 0, 0, 0.35);
  .label {
    width: 80px;
    flex-shrink: 0;
    font-weight: 500;
  }
}

.labeller__last-modified {
  font-size: 12px;
}

.ant-drawer-right.ant-drawer-open.auk-drawer--focused {
  .ant-drawer-content-wrapper {
    -webkit-animation: none;
    animation: none;
  }

  .labeller-header {
    -webkit-animation: focusBackground 2s ease 1;
    animation: focusBackground 2s ease 1;
  }
}

.labeller-body {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.production-label-field-input {
  margin: 0 !important;

  .col-form-label {
    display: flex;
    align-items: center;
    color: #fff;
    background: rgb(97, 97, 97);
    background: #001c55;
    padding: 0 10px;
    border-radius: 2px;
    justify-content: flex-start !important;
    max-height: 100%;
    white-space: pre;
    overflow-x: auto;
    text-transform: uppercase;
    font-size: 13px;

    &::-webkit-scrollbar {
      width: 0;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ffffff90;
    }
  }

  .col-8 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.barcode-container {
  width: 30px;
  display: flex;
  justify-content: center;
  align-self: center;
  border: 1px solid gray;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 10px;
  position: relative;

  .barcode-style {
    width: 14px;
    height: 14px;
    display: flex;
    z-index: 1;
    color: gray;
  }
}

.forward-labeller-tabs {
  .ant-tabs-nav {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .ant-tabs-content-holder {
    overflow: auto;
  }

  .ant-tabs-content {
    height: 100%;
  }

  hr {
    width: 60%;
    padding: 0;
    margin: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }

  .ant-empty-image {
    height: auto;
  }
}

.labels-vlist-container {
  flex-grow: 1;
  min-height: 300px;
}

.label-vlist-item {
  border: 1px solid #ddd;
  border-radius: 3px;
  height: 60px;
}

.forward-labeller {
  .label-vlist-item {
    cursor: pointer;
  }
}

.label-vlist-item__descriptor {
  white-space: nowrap;
  overflow-x: auto;
  flex-grow: 1;

  &::-webkit-scrollbar {
    width: 0;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ddd;
  }
}

.label-vlist-item__stop-icon {
  width: 30px;
  flex-shrink: 0;
  color: rgba(214, 6, 6, 0.8);
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: rgba(214, 6, 6, 1);
  }
}

.labeller-body-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;
}

.labeller-body-item-header {
  font-weight: 400;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.labeller-sku-select-dropdown {
  .ant-select-item-option-content {
    font-size: 12px;
  }
}

.label-notes-input.ant-input-textarea-show-count {
  .ant-input {
    height: 100px !important;
    resize: none;
  }
}

.label-notes-input {
  height: 100px !important;
  resize: none !important;
}
