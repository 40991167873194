.block-operation {
    position: relative;
    display: flex;
    width: 40px;
    height: 28px;
    border-radius: 3px;
    flex-shrink: 0;
    font-size: 12px;
    border: 1px solid rgb(0, 28, 85);
}

.block-operation__label {
    text-transform: uppercase;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }