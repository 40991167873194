.device-configuration {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .ant-breadcrumb-link > a {
    color: #1890ff !important;

    &.active {
      color: #616161 !important;
    }

    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.device-configuration__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  overflow: auto;
}

.device-configuration__submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
