$green: rgba(135, 208, 104, 1);
$darkgreen: rgba(119, 196, 86, 1);
$gray: darkgray;
$darkgray: gray;
$red: rgba(214, 6, 6, 0.9);
$darkred: rgba(214, 6, 6, 1);
$navy: rgba(0, 28, 85, 0.9);
$darknavy: rgba(0, 28, 85, 1);
$beige: rgba(255, 242, 232, 0.9);
$darkbeige: rgba(255, 242, 232, 1);
$salmon: rgba(255, 187, 150, 0.9);
$darksalmon: rgba(255, 187, 150, 1);

.auk-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.auk-dropdown-button {
  font-size: 12px;
  .ant-btn {
    margin-left: 0px !important;
    &:empty {
      display: none;
    }

    &.ant-dropdown-trigger {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.auk-button--round {
    .ant-btn {
      // Dropdown.Button
      border-radius: 50% !important;
      cursor: pointer;
      width: 32px;
      height: 32px;
    }
  }
}

.auk-button--round {
  border-radius: 50% !important;
  cursor: pointer;
  width: 32px;
  height: 32px;

  button:disabled {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
  }
}

.auk-button--solid-green {
  color: #fff !important;
  background: $darkgreen !important;
  border-color: $darkgreen !important;

  &:hover {
    color: #fff !important;
    background: $darkgreen !important;
    border-color: $darkgreen !important;
  }
}

.auk-button--solid-grey {
  color: #fff !important;
  background: $gray !important;
  border-color: $gray !important;

  &:hover {
    color: #222;
    background: $darkgray !important;
    border-color: $darkgray !important;
  }
}

.auk-button--solid-red {
  color: #fff !important;
  background: $red !important;
  border-color: $red !important;

  &:hover {
    color: #fff !important;
    background: $darkred !important;
    border-color: $darkred !important;
  }
}

.auk-button--solid-dark-blue {
  color: #fff !important;
  background: $navy !important;
  border-color: $navy !important;

  &:hover {
    color: #fff !important;
    background: $darknavy !important;
    border-color: $darknavy !important;
  }
}

.auk-button--volcano {
  color: #d4380d !important;
  background: $beige !important;
  border-color: $salmon !important;

  &:hover {
    color: #d4380d !important;
    background: $darkbeige !important;
    border-color: $darksalmon !important;
  }
}

.auk-toggle-button--green {
  &:hover {
    color: $darkgreen !important;
    border-color: $darkgreen !important;
  }
}

.auk-toggle-button--green.auk-button--solid-green {
  &:hover {
    color: #fff !important;
  }
}

.auk-select-filter {
  .ant-select-selector {
    background-color: #f5f5f5 !important;
    padding: 0 !important;
    width: 100%;
    border-radius: 20px !important;
  }

  .ant-select-arrow {
    left: 0px;
    right: unset;
    height: 100%;
    color: #616161;
    font-size: 14px;
    padding-left: 1em;
    top: 0px;
    margin-top: 0px;
    font-weight: 500;
  }
}

.auk-select-button {
  .ant-select-selector {
      border: 1px solid #1890FF !important;
  }

  .ant-select-selection-placeholder {
      color: #1890FF;
      text-align: center;
  }

  &:hover {
      .ant-select-selector {
          border-color: #40a9ff !important;
      }

      .ant-select-selection-placeholder {
          color: #40a9ff;
      }
      
  }
}

.ant-btn-text.auk-button--text {
  background: none;

  &:hover {
    background: none;
    color: #1890FF;
  }

  &:active {
    background: none;
  }

  &:focus {
    background: none;
  }
}
