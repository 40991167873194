.pareto-label-list {
  display: flex;
  flex-direction: column;
  font-size: 12px !important;
  width: 100%;
  height: 60vh;
  padding: 1em;

  .ant-table-cell {
    font-size: 12px;
  }

  .vtable-cell {
    font-size: 12px;
  }
}

.pareto-label-item {
  width: 100%;
  text-align: left;
}
