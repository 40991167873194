.target-setting {
  padding: 1em;

  .target-setting__header {
    .target-setting__header__left,
    .target-setting__header__center,
    .target-setting__header__right {
      width: 30%;
      flex-shrink: 0;
      display: flex;
    }

    .target-setting__header__center {
      justify-content: center;
    }

    .target-setting__header__right {
      justify-content: flex-end;
    }

    .target-setting__header_range-input {
      min-width: 130px;
      flex-grow: 1;
    }

    .target-setting__header_range-arrows {
      flex-shrink: 0;
    }

    .target-setting__header_range-arrow {
      margin: 0 6px;

      &:hover {
        cursor: pointer;
        color: #1890ff;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.4;
    }
  }

  .ant-calendar-picker-input,
  .rc-time-picker-input {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 14px;
  }

  .target-setting-action-wrapper {
    margin: 0px;

    &.mobile {
      padding: 20px;
      margin-right: 25px;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #ccc;
    }

    > .action-item {
      min-width: 200px;
      margin: 0px 15px 20px 0px;

      // &.custom-btn {
      //   min-width: 100px;
      //   text-transform: uppercase;
      // }

      // > .form-group {
      //   margin-bottom: 0px;
      // }

      &.action-arrow {
        min-width: 0px;
        margin-top: -3px;

        &.mobile {
          display: flex;

          > .icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            height: 35px;
            background-color: buttonface;
            border-radius: 5px;
            border: 1px solid #ccc;
            overflow: hidden;
          }

          svg {
            margin: 2px 10px 0px 10px;
          }
        }

        // .icon-wrapper {
        //   display: inline;

        //   &:first-child {
        //     margin-right: 20px;
        //   }
        // }

        // svg:hover {
        //   cursor: pointer;
        // }
      }
    }

    .has-search .form-control {
      padding-left: 2rem;
    }

    > .action-search-text {
      position: relative;

      .has-search .form-control-feedback {
        position: absolute;
        z-index: 2;
        display: block;
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        pointer-events: none;
        color: #aaa;
        top: 10px;
        margin-left: 10px;
      }
    }

    .btn.btn-today {
      margin: 0px;
      width: 100%;
      border: 1px solid #e6e6e6;
      text-transform: uppercase;
    }
  }

  > .target-setting-body {
    // margin: 20px 20px 20px 20px;
    display: inline-block;
    background-color: #fff;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > .row.timeline {
      margin: 0;
      background-color: #fff;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;

      .target-setting-static-column {
        padding-right: 0px;
        border: 1px solid #e6e6e6;
        // box-shadow: 7px -1px 14px 0px #ccc;
        border-top-left-radius: 7px;
      }

      > .target-setting-timeline {
        padding: 0px;
        border: 1px solid #e6e6e6;
        border-left: none;
        border-top-right-radius: 7px;
        // box-shadow: inset -7px 0px 5px 0px #ccc;

        .text-timeline {
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          border-right: none;

          &.show-border {
            border-right: 2px solid #e6e6e6;
          }

          p {
            margin: 0px;

            &.text-timeline-day {
              font-weight: bold;
            }
          }
        }
      }
    }

    > .row.assets-plan-table {
      margin: 0;
      flex-grow: 1;
      overflow: scroll;

      .asset-column {
        padding-right: 0px;
        padding-left: 0px;
        border: 1px solid #e6e6e6;
        border-top: none;
        border-bottom-left-radius: 7px;

        .ts-asset-column-name {
          margin: 0;
          position: absolute;
          border: 1px solid #e6e6e6;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 10px;
          font-size: 13px;
          font-weight: 400;

          &.ts-selected-asset {
            border-top: 2px solid #2bb6d6;
            border-bottom: 2px solid #2bb6d6;
            font-weight: 600;
          }
        }
      }

      > .plan-table {
        padding: 0px;
        border-bottom-right-radius: 7px;
        border: 1px solid #e6e6e6;
        border-left: none;
        border-top: none;
        // box-shadow: inset -7px 0px 5px 0px #ccc;

        .plan-col-resolution {
          width: 100%;
          height: 100%;
          position: relative;

          > .resolution {
            position: absolute;
            width: 100%;
            height: 100%;

            &.clicked {
              background-color: black;
              opacity: 0.2;
            }
          }

          > .resolution-blocked {
            background-image: repeating-linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.1) 0px,
              rgba(0, 0, 0, 0.1) 5px,
              transparent 5px,
              transparent 10px
            );
            position: absolute;
            left: 0;
            height: 60px;
          }

          > .resolution-asset-selected {
            border-top: 2px solid #2bb6d6;
            border-bottom: 2px solid #2bb6d6;
            position: absolute;
            left: 0;
            height: 60px;
          }

          > .tooltip-line {
            position: absolute;
            display: none;
            width: 2px;
            height: 100%;
            background-color: #ffd082;
            top: 0px;
            z-index: 1;

            &.right {
              right: 0;
            }

            > .tooltip-resolution-wrapper {
              position: relative;
              transform: rotate(-90deg);
              left: -3px;
              // top: 300px;
              // z-index: 3;

              > .tooltip-resolution {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 130px;
                padding: 5px;
                background-color: #fff;
                border-radius: 8px;
                font-weight: 500;
                border: 1px solid #ffd082;
              }

              > .tooltip-rectangle {
                width: 20px;
                height: 20px;
                transform: rotate(135deg);
                border: 1px solid #ffd082;
                background-color: #fff;
                border-top: none;
                border-left: none;
                margin-top: -26px;
                margin-left: -8px;
              }
            }
          }

          &:hover {
            // border-left: 2px solid #ffd082;

            // > .tooltip-resolution-wrapper {
            //   display: block;
            // }

            > .tooltip-line {
              display: block;
            }
          }
        }

        .plan-label {
          position: absolute;
          // border-radius: 5px;
          height: 45px;
          padding-left: 10px;
          color: #21243d;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          box-shadow: 2px -1px 6px 3px #ccc;
          display: flex;
          align-items: center;
          border-style: solid;
          border-left-width: 10px;
          border-top: none;
          border-bottom: none;

          background-color: #fff;
          // z-index: 3;

          &:hover {
            cursor: pointer;
          }

          &.plan-label--disabled {
            opacity: 0.3;
            &:hover {
              cursor: default;
            }
          }

          > .plan-lable-wrapper {
            height: 100%;
            width: 100%;
            // position: relative;
            display: flex;
            align-items: center;

            > .label-target {
              font-size: 35px;
              font-weight: 200;
            }

            > .label-calendar {
              margin-left: 10px;
              font-size: 12px;
              left: 47px;
              font-weight: 300;

              > .sku {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.target-setting-plan-modal {
  width: 60%;

  .panel {
    .panel-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;

      .row {
        width: 90%;

        .ant-input,
        .ant-picker {
          &.is-invalid {
            border: 1px solid #dc3545;
          }
        }
      }
    }
  }
}
