.asset-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .danger-zone__item {
    border: 1px solid #dc3545 !important;
    border-radius: 2px;
  }
}

.asset-form__body {
  display: flex;
}

.asset-form__image {
  width: 33%;
  flex-shrink: 0;
  min-width: 300px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.asset-form__fields {
  display: flex;
  flex-direction: column;
}

.asset-form__submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
