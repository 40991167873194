.ant-avatar {
  flex-shrink: 0;
}

.user-name-with-icon{
  max-width: 150px;
  flex-shrink: 0;
  
  &.ant-tag {
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 40px;
  }

  &:first-child {
    margin-right: 0px;
  }
}

.user-square-icon {
  img {
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
    border-radius: 3px;
    width: 30px;
    height: 30px;
  }
}

.user-square-placeholder {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-name-icon-popover-content-wrapper {
  display: flex;
  color: #fafafa !important;
  
  .user-square-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      box-shadow: 0px 1px 4px -1px rgba(255, 255, 255, 0.75);
      width: 50px;
      height: 50px;
    }

    .user-square-placeholder {
      box-shadow: 0px 1px 4px -1px rgba(255, 255, 255, 0.75);
      border-radius: 3px;
      width: 50px;
      height: 50px;
    }
  }

  .user-name-icon-popover-content {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    font-size: 14px;
  }
}

.users-condensed {
  display: flex;
  justify-content: flex-end;

  .ant-avatar {
    color: #d4380d !important;
    background: rgba(255,242,232, 0.9) !important;
    border-color: rgba(255, 187, 150, 0.9) !important;
    font-size: 12px;

    &:hover {
      color: #d4380d !important;
      background: rgba(255,242,232, 1) !important;
      border-color: rgba(255, 187, 150, 1) !important;
    }
  }

  .ant-dropdown-trigger {
    color: #d4380d !important;
    border-color: rgba(255, 187, 150, 0.9) !important;
    font-size: 12px;

    &:hover {
      color: #d4380d !important;
      background: rgba(255,242,232, 1) !important;
      border-color: rgba(255, 187, 150, 1) !important;
    }
  }
}

.users-dropdown {
  margin-left: -0.5em;
}
