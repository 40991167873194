.entity-schedule {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;

  .op-06 {
    opacity: 0.6;
  }

  .is-completed {
    background: #fafafa;
  }

  .b-add {
    background-color: #70ad47;
  }

  .button {
    margin: 0px 2px;
    // padding: 4px 4px;
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    &:disabled {
      background-color: #ddd;
      pointer-events: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .collapsible {
    .collapsible--header {
      position: relative;
      padding: 6px 0;
      border-bottom: 1px solid #ddd;
      font-weight: 500;

      &.collapsed {
        outline: none;
      }
    }

    margin-bottom: 2em;
  }

  .ant-pagination {
    .ant-pagination-item-link {
      display: flex;
      align-items: center;
    }
  }

  .ant-picker {
    width: 100%;
    height: 100%;

    // &.is-invalid {
    //   border: 1px solid #dc3545;
    // }

    .ant-picker-suffix {
      display: flex;
      align-items: center;
    }
  }

  .ant-select-selector {
    height: 100% !important;
  }
  .ant-select {
    height: 100%;
    width: 100%;
  }

  .is-invalid {
    border: 1px solid #dc3545;
  }
}

.schedule-panel {
  min-width: 60vw;
}

.schedule-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #eee;

  .vtable-cell {
    overflow: auto;
  }
}

.schedule-table__title {
  font-weight: 500;
  font-size: 16px;
}
