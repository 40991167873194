.browser-incompatible {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.browser-incompatible__body {
  width: 50%;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.75);
  padding: 1em;
  margin-top: 2em;
}

.browser-incompatible__icons {
  display: flex;
  flex-direction: row;
  width: 50%;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.75);
  padding: 1em;
  margin-top: 2em;
}
