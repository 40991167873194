.ant-layout-sider.layout-auth__sider {
    height: calc(100vh - 50px);
    position: absolute;
    z-index: 3;
    padding-top: 12px;
    background: #E5E8ED;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 12px;
    
    &.ant-layout-sider-collapsed {
        .ant-menu-title-content {
            display: none !important;
        }

        .ant-menu {
            background: #E5E8ED;
            padding-left: 0px;
            padding-right: 0px;
        }
    
        .ant-menu-submenu-title {
            border-bottom: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ant-menu-item-icon {
            display: flex;
            align-items: center;
        }

        .ant-layout-sider-trigger {
            div {
                justify-content: center;
            }
        }
    }

    .ant-layout-sider-children {
        overflow: auto;
        background: #E5E8ED;
    }

    .ant-menu {
        background: #E5E8ED;
        padding-left: 18px;
        padding-right: 18px;
    }

    .ant-menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px !important;
    }

    .ant-menu-item-disabled {
        display: none;
    }

    .ant-menu-submenu-disabled {
        display: none;
    }

    .ant-menu-title-content {
        display: flex;
    }

    .ant-menu-submenu-title {
        border-bottom: 1px solid #C1C7CE;
        padding: 0px !important;
    }

    .ant-layout-sider-trigger {
        background: #E5E8ED;
        color: rgba(0, 0, 0, 0.85);
        position: relative;
        padding: 0 25px;

        div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
    .ant-menu-item {
        display: flex;
        align-items: center;
        height: 56px;
        margin: 0px;
    }

    .ant-menu-title-content {
        display: flex;
        margin-left: 10px;
    }
}