$themes: (
        light: (
                primary: '#206487',
                secondary: '#006874',
                surface: '#F8FAFA',
                surface-container-low: '#F2F4F4',
                link: '#206487',
                error: '#BA1A1A',
        )
);

@each $name, $map in $themes {
  body.#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}

html, body {
  width: 100%;
  height: 100%;
}

html {
  overflow: hidden;
}

#root {
  background: var(--surface);
  overflow: auto;
}

a {
  color: var(--primary, #206487);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
}
