.block-csv__loaders {
  border-radius: 10px;
  background-color: #40a9ff;
  width: 40%;
  padding: 10px;

  .loaders-horizontal {
    .circle {
      background-color: #fff;
      width: 3px;
      height: 3px;
    }
  }
}

.ant-modal.auk-modal.block-csv__modal {
  width: 50vw !important;
}
