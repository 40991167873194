.auk__multi-option-input {
  .row {
    align-items: center;
    padding-left: 6px;
    height: 30px;
    font-weight: 300;

    label {
      margin: 0px;
      background-color: none;
      background: none;
      border: none;
      color: inherit;
      border-right: 1px solid #bbb;
      width: 70px;
    }

    input {
      flex-grow: 1;
      color: inherit;
      background-color: none;
      background: none;
      border: none;
      padding-left: 8px;

      :focus {
        outline: none;
      }

      &.is-invalid {
        border: 1px solid;
      }
    }

    &.active {
      font-weight: 500;
    }

    &.inactive {
      opacity: 0.5;
    }
  }
}
