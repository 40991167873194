.auk-multi-select {
  min-width: 180px;
  .ant-select-selection-item-content {
    max-width: 100px;
  }
}

.auk-select-filter {
  &.auk-select-filter-multi {
    .ant-select-selection-overflow {
      width: 100%;
      flex-wrap: nowrap;
      height: 32px;
      overflow: hidden;
      padding-left: 130px;
    }

    .ant-select-selection-item-content {
      display: none;
    }

    .ant-select-arrow {
      width: 130px;
    }

    .ant-select-selection-overflow-item-rest {
      display: none !important;
    }
  }
}
