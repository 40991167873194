.language-row {
  margin-bottom: 20px;

  &:first-child {
    margin-top: 15px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  > .language-item {
    text-align: center;

    &.active {
      font-weight: 500;
    }

    &:hover {
      cursor: pointer;
      font-weight: bold;
    }

    > .lang-title {
      font-size: 15px;
      margin: 0px;
    }
  }
}

.language-selection-container {
  position: relative;
  display: flex;

  > img:hover {
    cursor: pointer;
  }
}
