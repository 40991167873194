.auk-drawer {
  .ant-drawer-close {
    color: #fff;
    padding: 0;
    margin-top: 1rem;

    &:hover {
      color: #fff;
      font-size: 18px;
    }
  }

  .ant-drawer-content {
    pointer-events: all;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-content-wrapper {
    width: 30% !important;
    min-width: 360px;
  }
}

.auk-drawer__handler.auk-drawer__handler--expanded {
  display: none;
}

.auk-drawer__handler {
  position: fixed;
  top: 210px;
  right: 0px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;

  .auk-drawer__handler__icon {
    cursor: pointer;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: absolute;
    top: 20px;
    width: 30px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: rgba(0, 28, 85, 1);
    color: #fff;

    &:hover {
      font-size: 18px;
    }
  }
}

@-webkit-keyframes focusBackground {
  from,
  to {
    background-color: rgba(0, 28, 85, 1);
  }
  20% {
    background-color: #0370d6;
  }
}
@keyframes focusBackground {
  from,
  to {
    background-color: rgba(0, 28, 85, 1);
  }
  20% {
    background-color: #0370d6;
  }
}

.ant-drawer-right.ant-drawer-open.auk-drawer--focused {
  .ant-drawer-content-wrapper {
    -webkit-animation: focusBackground 2s ease 1;
    animation: focusBackground 2s ease 1;
  }

  .auk-drawer__handler__icon {
    -webkit-animation: focusBackground 2s ease 1;
    animation: focusBackground 2s ease 1;
  }
}

.auk-drawer__close {
  position: absolute;
  right: 1em;
  top: 0.5em;
  z-index: 2;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.auk-drawer__close__icon {
  &:hover {
    font-size: 18px;
    cursor: pointer;
  }
}

@media screen and (max-width: 990.98px) {
  .auk-drawer {
    .ant-drawer-content-wrapper {
      width: 360px !important;
    }
  }
}

// @media screen and (max-width: 575.98px) {
//   .auk-drawer {
//     .ant-drawer-content-wrapper {
//       width: 360px !important;
//     }
//   }
// }
