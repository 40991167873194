.auk-issue-tree-select {
    width: 100%;
    .ant-select-selector {
        width: 100%;
        border-radius: 20px !important;
    }
}

.auk-issue-tree-select--bg-us {
    .ant-select-selector {
        color: #fafafa;
        background-color: hsl(0, 0%, 75%) !important;
        border-color: hsl(0, 0%, 75%) !important;
    }

    .ant-select-arrow {
        color: #fafafa;
    }

    .ant-select-clear {
        background-color: hsl(0, 0%, 75%) !important;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.auk-issue-tree-select--bg-pd {
    .ant-select-selector {
        color: #fafafa;
        background-color: hsl(0, 0%, 50%) !important;
        border-color: hsl(0, 0%, 50%) !important;
    }

    .ant-select-arrow {
        color: #fafafa;
    }
    
    .ant-select-clear {
        background-color: hslhsl(0, 0%, 50%) !important;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.auk-issue-tree-select--bg-bd {
    .ant-select-selector {
        color: #fafafa;
        background-color: hsl(0, 60%, 43%) !important;
        border-color: hsl(0, 60%, 43%) !important;
    }

    .ant-select-arrow {
        color: #fafafa;
    }

    .ant-select-clear {
        background-color: hsl(0, 60%, 43%) !important;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.auk-issue-tree-select--bg-st {
    .ant-select-selector {
        color: #fafafa;
        background-color: hsl(0, 60%, 65.2%) !important;
        border-color: hsl(0, 60%, 65.2%) !important;
    }

    .ant-select-arrow {
        color: #fafafa;
    }

    .ant-select-clear {
        background-color: hsl(0, 60%, 65.2%) !important;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.auk-issue-tree-select--bg-ms {
    .ant-select-selector {
        color: #fafafa;
        background-color: hsl(42, 100%, 50%) !important;
        border-color: hsl(42, 100%, 50%) !important;
    }

    .ant-select-arrow {
        color: #fafafa;
    }

    .ant-select-clear {
        background-color: hsl(42, 100%, 50%) !important;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.auk-issue-tree-select--bg-sl {
    .ant-select-selector {
        color: #fafafa;
        background-color: hsl(42, 100%, 75%) !important;
        border-color: hsl(42, 100%, 75%) !important;
    }

    .ant-select-arrow {
        color: #fafafa;
    }

    .ant-select-clear {
        background-color: hsl(42, 100%, 75%) !important;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.auk-issue-tree-select--bg-rj {
    .ant-select-selector {
        color: #fafafa;
        background-color: hsl(261, 50%, 65%) !important;
        border-color: hsl(261, 50%, 65%) !important;
    }

    .ant-select-arrow {
        color: #fafafa;
    }

    .ant-select-clear {
        background-color: hsl(261, 50%, 65%) !important;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.auk-issue-tree-select--bg-rw {
    .ant-select-selector {
        color: #fafafa;
        background-color: hsl(261, 50%, 85%) !important;
        border-color: hsl(261, 50%, 85%) !important;
    }

    .ant-select-arrow {
        color: #fafafa;
    }

    .ant-select-clear {
        background-color: hsl(261, 50%, 85%) !important;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}