.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-overlay {
  position: absolute;
  width: 100%;
  background-color: #fff;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  top: 0
}
