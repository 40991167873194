.impact-reports-list {
    display: flex;
    flex-direction: column;
    border: 1px solid #C1C7CE;
    border-radius: 4px;
}

.impact-reports-list__header {
    display: flex;
    justify-content: space-between;
    color: #181C1F;
    font-size: 14px;
    height: 36px;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 10px;
    border-bottom: 1px solid #C1C7CE;
}

.impact-reports-list__items {
    max-height: 30vh;
    overflow: auto;
}

.impact-reports-list__header__title {
    display: flex;
    align-items: center;
}

.impact-reports-list__header__icon {
    color: #181C1F;
}

.impact-reports-list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
}

.impact-reports-list-item--active {
    background: #C6E7FF;
}

.impact-reports-list-item__name {
    color: #181C1F;
}

.impact-reports-list-item__date {
    color: #71787E;
    width: 120px;
    flex-shrink: 0;
    text-align: right;
}