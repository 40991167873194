.breadcrumbs {
  font-weight: 300;
  color: #8a8a8a;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 14px;

  a {
    color: #8a8a8a;
    margin: 0 8px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.pilot {
  padding: 2px 6px;
  font-size: 10px;
  background-color: #ddd;
  color: #222;
  border-radius: 2px;
  font-weight: 400;
  height: 16px;
}
