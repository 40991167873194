.ant-notification {
  .ant-notification-notice {
    .ant-notification-notice-content {
      ol {
        padding-inline-start: 0;
      }
    }
  }
}

.flash {
  position: fixed;
  z-index: 9999;
  height: 50px;
  width: 50%;
  font-size: 12px;
  color: white;
  display: flex;
  left: 50%;
  transform: translateX(-50%);

  .flash-notify {
    margin: auto;
    height: auto;
    // border: 0.5px solid grey;
    border-radius: 3px;
    padding: 0 20px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .flash-clear {
      align-self: flex-end;
      margin-right: -15px;
      cursor: pointer;
    }

    .flash-message {
      margin-top: -18px;
      padding-right: 10px;
      padding: 20px 10px;
    }

    .flash--error {
      text-align: center;
      color: maroon;
    }

    .flash-details {
      font-size: 10px;
      color: maroon;
      margin-bottom: 10px;
    }

    .block {
      .heading {
        font-size: 11px;
      }

      text-align: left;
      width: 80%;
      margin: 0 auto;
    }
  }
}
