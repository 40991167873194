.day-button {
  &.first-child .day-button-container,
  &:first-child .day-button-container {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  &.last-child .day-button-container,
  &:last-child .day-button-container {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }

  .day-button-container {
    width: 40px;
    height: 40px;

    &.start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &.end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    &.highlight {
      $color: var(--primary);
      background: $color;
      background: rgba(32, 100, 135, 0.1) !important;
    }
  }

  .day-button-btn {
    width: 40px;
    height: 40px;
    border-radius: 100% !important;
    text-transform: none !important;
    color: #000000 !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.75 !important;
    letter-spacing: 0.00938em !important;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    outline: 0 !important;

    &.today {
      background: #f05555 !important;
      color: #FFFFFF !important;
    }

    &.active {
      background: var(--primary) !important;
      color: #FFFFFF !important;
    }

    &.disabled {
      opacity: 0.4;
    }
  }
}
