.react-select-container {
  border-radius: 2px;
  min-height: 100%;
  border-color: rgba(191, 191, 191, 0.342);

  &.is-invalid {
    .react-select__control {
      border-color: #dc3545;
    }

    .react-select__control--is-focused {
      box-shadow: none;
    }
  }

  .react-select__control {
    min-height: auto;
  }

  .react-select__dropdown-indicator {
    padding: 6px;
  }

  .react-select__clear-indicator {
    padding: 6px;
  }

  .react-select__menu {
    z-index: 3;
  }
}

.react-select__option {
  color: gray;
  background-color: white;
}

.react-select__option--is-focused {
  background-color: gray;
  color: white;
}
