.device-interface-select {
    width: 100%;
    min-height: 40vh;
    display: flex;
    padding: 10%;
    justify-content: center;
    // align-items: center;
    flex-direction: column;

    .ant-select {
        width: 100%;
    }
}