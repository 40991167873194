$red: #dc3545;
$blue: #40a9ff;
$blueShadow: rgba(24, 144, 255, 0.2);

.auk-input {
  width: 100%;
  &.is-invalid {
    border: 1px solid $red;
  }
}

.auk-select {
  width: 100%;
  &.is-invalid {
    border: 0px;
    .ant-select-selector {
      border-color: $red !important;
      box-shadow: none !important;

      &:hover {
        border-color: $blue !important;
        box-shadow: 0 0 0 2px $blueShadow !important;
      }
      &:focus {
        border-color: $blue !important;
        box-shadow: 0 0 0 2px $blueShadow !important;
      }
    }
  }
}

.auk-timerange-picker,
.auk-date-picker,
.auk-range-picker {
  width: 100%;
  &.is-invalid {
    border: 1px solid $red;

    &:focus {
      border-color: $blue;
    }
  }
}

.ant-picker-focused {
  border-color: $blue !important;
}
