.trend-analysis {
  display: flex;
  flex-direction: column;
  // max-width: calc(100vw - 50px);

  .ant-picker {
    .ant-picker-suffix {
      display: flex;
    }

    .ant-picker-clear {
      display: flex;
    }
  }

  .trend-header {
    display: flex;
    width: 100%;
    height: 30px;
    margin-bottom: 1em;

    .button {
      margin-left: 10px;
      padding: 4px 8px;
      background-color: lightgray;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: darkgray;
        color: #fff;
      }

      &.selected {
        background-color: gray;
        color: #fff;
      }
    }
  }

  .trend-body {
    height: calc(100% - 50px);


    .trend-navigator__buttons {
      width: 80px;
      display: flex;
      height: 100%;
      flex-shrink: 0;
      padding: 10px;
      align-items: center;
      justify-content: center;

      .load {
        border-radius: 4px;
        border: 1px solid #ddd;
        height: 40px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:active {
          box-shadow: none;
        }
      }
    }

    .trend-rows--wrapper {
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      border: 1px solid #ddd;
      border-radius: 2px;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-right: 2em;
      position: relative;

      .trend-rows {
        width: 100%;
        height: 100%;
        padding-top: 0;

        .trend-row {
          .chart__header {
            font-size: 12px;
            width: 100%;
            text-align: center;
          }
          .ant-picker {
            padding: 0px 6px;

            .ant-picker-input {
              input {
                font-size: 12px;
              }
            }
          }

          border-bottom: 1px solid #dcdcdc;
          display: grid;
          display: -ms-grid;
          -ms-grid-columns: 150px auto 250px;
          grid-template-columns: 150px auto 250px;
          -ms-grid-rows: auto;
          grid-template-rows: auto;
          grid-template-areas: 'dropdown chart pie';
          padding: 5px;
          padding-left: 1em;
          padding-right: 1em;

          &:last-child {
            border: none;
          }

          @media screen and (max-width: 575.98px) {
            display: grid;
            display: -ms-grid;
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            -ms-grid-rows: auto;
            grid-template-rows: auto;
            grid-template-areas:
              'dropdown pie'
              'chart chart';
          }

          .trend-row__dropdowns {
            display: flex;
            flex-direction: column;
            height: 100%;
            -ms-grid-row: 1;
            -ms-grid-column: 1;
            grid-area: dropdown;
            position: relative;
            padding-bottom: 10px;
            @media screen and (max-width: 575.98px) {
              -ms-grid-row: 1;
              -ms-grid-column: 1;
              grid-area: dropdown;
            }

            .chart-remove--container {
              .chart-remove {
                background-color: #d9d9d9;
                width: 18px;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 1px;
                cursor: pointer;
                margin-bottom: 5px;
              }
            }

            .ant-calendar-picker-input {
              background-color: #fafafa;
              border: 1px solid hsl(0, 0%, 80%);
            }

            .react-select-container {
              min-height: 0;
              .react-select__control {
                height: 20px;
                border-radius: 3px;
                font-size: 12px;
                font-weight: 400;
                color: #3f3f3f;
              }

              .react-select__menu {
                margin-top: 0px;
                font-size: 12px;
                text-align: left;
              }

              .react-select__value-container {
                padding: 0px;
                padding-left: 4px;
                height: 20px;

                div {
                  // height: 20px;
                  padding: 0;
                }
              }

              .react-select__single-value {
                color: #3f3f3f;
              }

              .react-select__dropdown-indicator {
                padding: 0;
                color: #bbb;
              }

              .react-select__input {
                margin-top: 0;
                padding: 0;
              }

              .react-select__placeholder {
                top: 0;
                transform: none;
                font-size: 12px;
                // margin-top: 2px;
                padding-left: 4px;
              }

              .react-select__indicator-separator {
                display: none;
              }
            }
            .chart-selectors__container {
              margin: auto 0;

              .link-to-asset {
                cursor: pointer;
                font-size: 12px;
                width: 100%;
                background-color: #268bff;
                border-radius: 2px;
                height: 18px;
                color: #fff;
                font-weight: 400;
                text-align: start;
                padding-left: 8px;
              }
            }
          }

          .trend-row__chart-wrapper {
            display: flex;
            flex-direction: column;
            // margin-left: 10px;
            // margin-right: 10px;
            text-align: start;
            -ms-grid-row: 1;
            -ms-grid-column: 2;
            grid-area: chart;

            @media screen and (max-width: 575.98px) {
              -ms-grid-row: 2;
              -ms-grid-column: 1;
              -ms-grid-column-span: 2;
              grid-area: chart;
            }

            .trend-row__chart {
              flex-grow: 1;
            }

            .labels {
              width: 100%;
              flex-shrink: 0;
            }

            .chart {
              position: relative;
              width: 100%;
              height: 100%;
            }
          }
          .trend-row__oee {
            display: flex;
            flex-direction: row;
            align-items: center;
            -ms-grid-row: 1;
            -ms-grid-column: 3;
            grid-area: pie;

            @media screen and (max-width: 575.98px) {
              -ms-grid-row: 1;
              -ms-grid-column: 2;
              grid-area: pie;
            }

            .oee-dials__lapq {
              height: 70%;
            }
          }
          .main-row-flagchart {
            display: flex;
            flex-direction: column;
            width: 100%;
            .flagchart {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 30px;
            }
          }
        }
      }

      .legend-wrapper {
        padding-left: 1em;
        padding-right: 1em;
        // position: absolute;
        bottom: 0;

        .legend {
          padding-top: 1em;
          left: 0;
          // border-top: 3px solid #ddd;
        }
      }
    }
  }
}
.trend-navigator {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow: auto;
  border-radius: 2px;
  border: 1px solid #ddd;
  padding: 1em 0;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.75);
  background: #fafafa;
  flex-shrink: 0;

  .ant-picker {
    .ant-picker-input {
      input {
        font-size: 13px;
      }
    }
  }

  h6 {
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    padding-left: 15px;
    color: #c10603;
    text-transform: uppercase;
    text-align: center;
  }

  .col-form-label {
    margin-right: 0px;
    padding-right: 0px;
  }

  .form-group {
    margin-bottom: 4px;
  }

  .react-select__value-container {
    font-size: 13px;
  }

  .wrap-multi-input {
    .col-form-label {
      align-items: flex-start !important;
    }
  }

  .auk__multi-option-input {
    width: 100%;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    background: #fafafa;

    .auk__multi-option-input__row {
      flex-wrap: nowrap;
      height: 25px;
      margin: 5px 0;

      label {
        width: 70px;
        flex-shrink: 0;
        font-size: 12px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 4px;
        font-weight: 400;
      }

      .auk__multi-option-input__input {
        border: 0px;
        min-width: 50px;
        font-size: 13px;

        &.is-invalid {
          border: 1px solid #dc3545;
          border-radius: 2px;
        }
      }
    }
  }

  .btn {
    border-radius: 4px;
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
  }
}


.trend-navigator__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.trend-navigator__footer {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 1em;
  padding-bottom: 0;
  width: 100%;
}
