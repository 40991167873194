.asset-widget {
  position: relative;
  // height: 24%;
  border-radius: 3px;
  padding: 0.25rem;

  hr {
    width: 80%;
    margin: 0 auto;
    margin-top: 0.5rem;
  }

  &:last-child {
    hr {
      display: none;
    }
  }
}
