.asset-default {
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  .with-block-users {
    width: calc(100% - 20px);
  }

  .block-title {
    width: 50%;
  }
}

.asset-default__body {
  display: flex;
  min-height: 0; // for nested flex-box issues in firefox
  flex-grow: 1;
  padding: 1em 0;
  padding-top: 1em;

  display: flex;
  flex-direction: row;
  overflow: hidden;

  .ant-collapse-ghost {
    .ant-collapse-item {
      .ant-collapse-content {
        .ant-collapse-content-box {
          // padding-top: 0px;
          padding: 0px;
        }
      }
    }
  }
}

.asset-default__body__charts {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.asset-default__body__widgets {
  // width: 300px;
  width: 30%;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  flex-shrink: 0;
  justify-content: space-between;
  border-left: 1px solid #ddd;
}

.asset-widget {
  .percent-bar-container {
  }

  .percent-bar__label {
    width: 20%;
  }

  .percent-bar__value-label {
    width: 20%;
  }
}

.asset-default__oee-chart {
  .ant-collapse-content-box {
    overflow-y: scroll;
  }
}

.asset-default__charts {
  flex-grow: 1;
  overflow: scroll;
  position: relative;
}

// .ant-collapse-item:not(.ant-collapse-item-active) {
//   .ant-collapse-header {
//     border: 1px solid #ddd;
//   }
// }

// .ant-collapse-arrow {
//   position: relative !important;
//   top: 0px !important;
//   left: 0px !important;
//   transform: unset !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .ant-collapse-extra {
//   display: flex;
//   align-items: center;
// }

.asset-default__charts__labels {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.chart-editable {
  width: 100%;
  height: 100%;
  position: relative;

  .auk-dropdown-button {
    .ant-btn {
      border: 0;
      box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
    }
  }
}

.chart-editable__header {
  font-size: 12px;
  font-weight: 400;
  position: relative;

  .title {
    // text-align: center;
    position: absolute;
    width: 100%;
    top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .stdtime-input {
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;

    &.disabled {
      pointer-events: all;

      .onactive__container {
        pointer-events: none;
      }
    }

    label {
      width: 90px;
      flex-shrink: 0;
      text-align: right;
      cursor: default;
      padding-left: 8px;
    }

    .onactive__container {
      width: 100%;
      height: 24px;
      margin: 0 12px;

      .onactive__placeholder {
        border-bottom: 1px solid #ddd;
        padding-left: 6px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        &:hover {
          border-bottom: 1px solid #268bff;
        }
      }

      .onactive__input-wrapper {
        height: 100%;
        border: 1px solid #268bff;
        border-radius: 2px;

        .onactive__input {
          padding: 0;
          padding-left: 6px;
          height: 100%;
          border: 0;
        }

        .onactive__input-save {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
  }
}

.chart-editable__header__config {
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 575.98px) {
    display: none;
  }
}

.chart-editable__body {
  flex-grow: 1;
  height: 134px; // for old chrome
}

.legend-popover-container {
  .legend {
    // width: 100%;
    // left: 0;
  }
}

@media screen and (max-width: 990.98px) {
  .asset-view.spa-wrapper {
    padding: 0px;
  }

  .asset-default__body {
    flex-direction: column;
    overflow: auto;
    padding: 1em;
  }
  .asset-default__body__widgets {
    width: 100%;
    margin-left: 0;
  }
  .asset-widget {
    min-height: 220px;
  }
  .asset-default__charts {
    flex-grow: unset;
    overflow: unset;
  }
}

@media screen and (max-width: 575.98px) {
  .asset-default .block-title {
    width: 100%;
    padding: 0.5em;
  }

  .auk-blocks-select.ant-select {
    width: 100%;
    margin-bottom: 0.25rem;
  }

  .asset-default__body {
    padding: 0;
  }

  .chart-editable__header {
    .title {
      position: relative;
      top: 0;
    }
  }
}


