.vtable-cell {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.auk-virtual-table {
  .ant-table-cell {
    font-weight: 400;
    padding: 8px;
    font-size: 14px;
  }
}
