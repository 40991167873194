.block-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.block-content__main {
    overflow: scroll;
    flex-shrink: 0;
    margin-top: 0.5em;
    width: 100%;
}

.block-content__list {
    margin-top: 10px;
    flex-grow: 1;
    overflow: scroll;
}