.header {
  display: flex;
  flex-direction: column;

  .app-title {
    display: none;

    div {
      color: rgb(255 255 255);
      margin-left: 1.5rem;
      padding-left: 1.5rem;
      border-left: 1px solid rgb(255 255 255);
      height: 24px;
      width: 180px;
      font-weight: 500;
      font-size: 14px;
      font-family: Roboto,sans-serif;
    }
  }

  .nav-color {
    background-color: #3f3f3f;
  }

  .bg-dark-custom {
    background-color: rgba(52, 58, 64, 0.9); //#343a40;
  }

  .hidden-lg {
    // display: unset;
    display: flex;
    align-items: center;
  }

  .navbar {
    padding: 0;
    // height: 50px;

    .company-name {
      color: #fff;
      display: flex;
      margin-left: 5px;
      font-weight: 400;
      align-items: center;

      .ant-select {
        min-width: 250px;
        max-width: 300px;

        @media (min-width: 992px) {
          max-width: 250px;
        }

        @media (max-width: 575.98px) {
          min-width: 200px;
          max-width: 200px;
        }

        &:hover {
          .ant-select-selection-item {
            opacity: 0.8;
          }
        }

        .ant-select-selector {
          background: #3f3f3f;
          border: 0px;
          border-bottom: 1px solid #40a9ff;
          color: #fafafa;
          border-radius: 0px;
        }

        .ant-select-open {
          .ant-select-selection-item {
            opacity: 0.8;
          }
        }

        .ant-select-arrow {
          color: #fafafa;
        }
      }
    }

    .workspace {
      height: 28px;
      tab-size: 4;
      font-family: Roboto,sans-serif;
      font-size: 14px;
      font-feature-settings: normal;
      font-variation-settings: normal;
      line-height: inherit;
      box-sizing: border-box;
      border: 0 solid #e5e7eb;
      text-decoration: inherit;
      display: flex;
      align-items: center;
      border-radius: .25rem;
      background-color: rgb(255 255 255);
      padding-left: .625rem;
      padding-right: .625rem;
      padding-top: .25rem;
      padding-bottom: .25rem;
      color: rgb(55 65 81);
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;
      width: 166px;
    }

    .navbar-collapse {
      justify-content: space-between;
      height: 50px;
      width: 100%;
    }

    .navbar-nav {
      .controls {
        display: flex;
        margin-left: 27px;
        color: darkgray;

        .control-item {
          // display: inline-block;
          margin-right: 12px;
          cursor: pointer;
          display: flex;
          align-items: center;

          &:hover {
            color: lightgray;
          }
        }
      }

      .nav-item {
        cursor: pointer;

        &.btn-header {
          color: white;
          border: 1px solid #fff;

          &:hover {
            background-color: #ffffff;
            color: #000000;
          }
        }
      }

      .notifications-menu {
        .dropdown-menu {
          a {
            color: #444444;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 10px;

            .glyphicon,
            .fa,
            .ion {
              width: 20px;
            }
          }
        }
      }

      .messages-menu {
        .dropdown-menu {
          img {
            margin: auto 10px auto auto;
            width: 40px;
            height: 40px;
          }

          h4 {
            padding: 0;
            margin: 0 0 0 45px;
            color: #333;
            font-size: 15px;
            position: relative;
          }

          p {
            margin: 0 0 0 45px;
            font-size: 12px;
            color: #888888;
          }
        }
      }

      .user-menu {
        .nav-link {
          .logo {
            display: inline-block;
            padding-left: 0.5rem;

            .l {
              text-overflow: ellipsis;
              overflow: hidden;
              color: white;
              font-weight: 600;
            }

            .e {
              text-overflow: ellipsis;
              overflow: hidden;
              font-weight: 200;
              color: rgb(219, 219, 219);
            }
          }
        }

        .dropdown-menu {
          position: absolute;
          margin: 0;
          right: 0;
          left: auto;
          border-radius: 0;
        }

        .dropdown-toggle::after {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 17px;
          vertical-align: 15px;
          content: '';
          border-top: 9px solid;
          border-right: 7px solid transparent;
          border-bottom: 0;
          border-left: 7px solid transparent;
        }
      }

      .header-ul {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        background-color: #ffffff;
        padding: 7px 10px;
        border-bottom: 1px solid #f4f4f4;
        color: #333;
        font-size: 14px;
      }

      .footer-ul a {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        font-size: 12px;
        background-color: #fff;
        padding: 7px 10px;
        border-bottom: 1px solid #eeeeee;
        color: #333;
        display: block;
      }

      .label {
        position: absolute;
        top: 10px;
        right: 6px;
        text-align: center;
        font-size: 8px;
        padding: 2px 3px;
        line-height: 0.9;
        background-color: #333;
        border-radius: 0.25em;
      }

      a.a-not-mes:after {
        display: none;
      }

      .nav-link {
        padding-right: 15px;
        padding-left: 15px;
        display: block;
        padding-top: 1px;
        padding-bottom: 1px;
      }

      .ant-avatar {
        width: 40px;
        height: 40px;
      }

      .notifications-menu > .dropdown-menu,
      .messages-menu > .dropdown-menu,
      .tasks-menu > .dropdown-menu {
        width: 280px;
        padding: 0 0 0 0;
        margin: 0;
        top: 100%;
      }

      .messages-menu > .dropdown-menu li .menu > li > a,
      .notifications-menu > .dropdown-menu li .menu > li > a {
        margin: 0;
        padding: 10px 10px;
        display: block;
        white-space: nowrap;
        border-bottom: 1px solid #f4f4f4;
      }
    }
  }

  .log-mes {
    display: flex;
    flex-direction: column;
  }

  .dropdown-menu-over .menu {
    max-height: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: hidden;
  }

  a.navbar-brand {
    width: 165px;
  }
}

@media (min-width: 992px) {
  .header {
    .hidden-lg {
      display: none;
    }

    .app-title {
      display: flex;
      align-items: center;
    }
  }
}

.header--unauth {
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.header-auth {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  align-items: center;
}
