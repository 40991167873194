.rc-time-picker-panel-inner {
  width: 100%;

  .rc-time-picker-panel-select {
    width: 50%;
  }
}

.ant-calendar-picker-input {
  &.ant-input {
    width: 120px;
    padding: 0;
    border-radius: 3px;
    line-height: 1;
    height: 100%;
    padding-left: 6px;
    font-size: 12px;
    border: 0;
    background-color: #d9d9d9;
    color: #3f3f3f;
    cursor: pointer;

    &.is-invalid {
      border-color: #dc3545;
    }
  }
}

a:not([href]):not([tabindex]) {
  &.rc-calendar-today-btn {
    color: #f44336;
  }
}
