.input-group {
  outline: 1px solid #bfbfbf57;
  margin-bottom: 0px;
  border-radius: 2px;
  width: auto;

  .input-group-prepend {
    margin: 0;
    border: 0;
    border-radius: 0;
    min-width: 40px;

    .input-group-text {
      background-color: #fff;
      color: #bfbfbf;
      border-radius: 0;
      border: 0;
    }
  }

  input {
    border: 0;
  }
}