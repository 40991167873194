.plan-container {
  width: 100%;
  text-align: center;
  position: relative;

  & > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.plan-container-goal {
  width: 100%;
}

.plan-container-goal--align-right {
  display: flex;
  justify-content: flex-end;
}

.plan-container-icon {
  position: absolute;
  top: 10px;
  right: 0px;
}

.plan-container-icon-positive {
  color: #41BF69;
}

.plan-container-icon-negative {
  color: var(--error);
}
