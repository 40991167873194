.objective-page {
  width: 100%;
  height: 100%;

  .objective-page-goals {
    margin-top: 16px;
  }

  .objective-page-filter {
    margin-top: 16px;
    display: flex;
  }
}
