.btn-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px 10px 12px !important;
  color: #A4A6A6 !important;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;

  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    margin-left: 8px;
  }
}
