.asset-sku-charts {
    .ant-tabs-content-holder {
        min-height: 20vh;
    }

    .ant-tabs-content {
        height: 100%;
    }

    .ant-input[disabled] {
        background-color: #ffffff;
        color: #616161;
    }
}

.asset-sku-charts-content {
    height: 60vh;
    display: flex;
    flex-direction: column;
}

.sku-chart-form-row {
    display: flex;
    width: 100%;
    flex-direction: space-between;

    .ant-form {
        width: 100%;
    }

    .ant-form-item-row {
        width: 100%;
    }
}

.sku-chart-form-header {
    &.sku-chart-form-row  {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 12px;
    }
}

.sku-chart-form-row-item {
    display: flex;
    padding: 1em 0.5em;
}

.sku-chart-form-row-item {
    padding: 0px 6px !important;
}

.sku-chart-form-row-item--50 {
    width: 50%;
    flex-shrink: 0;
}

.sku-chart-form {
    flex-grow: 1;
    // max-height: 30vh;
    overflow: auto;
}