.block-users-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  // width: 100%;

  .ant-form-item {
    width: 90%;
  }
}

.with-block-users {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-users-wrapper {
  display: flex;
  overflow: hidden;
  flex-direction: row-reverse;
  align-items: center;
}

.block-users {
  display: flex;
  max-width: 450px;
  overflow: auto;
  flex-direction: row-reverse;
  align-items: center;
}


.add-block-users {
  margin-left: 0.5em;
}

.block-users--mobile {
  display: none;
}

@media screen and (max-width: 575.98px) {
  .add-block-users, .block-users {
    display: none;
  }

  .block-users--mobile {
    display: flex;
    align-items: center;
  }

  .block-users-wrapper {
    width: 60px;
    overflow: visible;
  }
}