.asset-configure-tabs {
  .device-scan {
    width: 100%;
    max-width: 100%;
  }

  .device-scan__icons {
    justify-content: center;
  }

  .ant-tabs-content-top {
    height: 100%;
  }
}

.chart-order__submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
