.viz-asset-primary {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;

  .ant-empty-normal {
    margin: 0px;
  }
}

.viz-asset-primary__status {
  position: absolute;
  width: 100%;
  height: 4%;
}

.viz-asset-primary__image {
  border-radius: 2px;
  pointer-events: none;
  width: 80%;
  height: 30%;
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
  // display: flex;
  // justify-content: center;
  // align-items: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.viz-asset-primary__content {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.indicator-tag {
  display: flex;
  justify-content: center;

  .tag--button {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: #fff;
    width: 90%;
    overflow: hidden;
  }
}

.chart-summary {
  display: flex;
  font-size: 20px;

  .chart-summary-row {
    height: 45%;
    text-align: center;
    font-weight: 600;
  }
}

.tag--tooltip {
  position: absolute;
  background-color: rgba(2, 2, 2, 0.9);
  z-index: 2;
  color: #fff;
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 12px;
  // width: 100%;
  max-width: 40vw;

  div {
    white-space: break-spaces;
  }
}
