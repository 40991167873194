.report-page {}

.report-page__header {
    display: flex;

    .ant-btn-compact-first-item {
        flex-grow: 1;
    }
}

.report-page__sub-header {
    display: flex;
}

.report-page__summary {
    margin-bottom: 1em;
}

.report-page__content {
    flex-grow: 1;
}
