.control-panel-wrapper {
  display: flex;
  flex-direction: column;
}

.control-panel {
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 1px 3px 12px -4px rgba(0, 0, 0, 0.35);
  padding: 0.5em 1em;
  flex-shrink: 0;
  width: calc(100vw - 50px);

  .circle {
    background-color: #fff;
    border-radius: 50%;
    height: 8px;
    width: 8px;
  }

  .blink {
    animation: blinker 1.5s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .cp-info {
    display: flex;
    // width: 240px;
    flex-direction: column;
  }

  .cp-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .gateway {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .short-logo {
    display: flex;
    align-items: center;

    img {
      height: 45px;
    }
  }

  .time-range {
    display: flex;
  }

  .control-panel-item {
    height: 50%;

    &.current-time {
      top: 22px;
      left: 75px;
      padding: 0 8px;
    }

    &.time-range {
      width: 280px;
      .row {
        flex-wrap: nowrap;
        margin: 0px;
      }
    }

    &.status {
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: 100%;
    }
  }

  .time-control-inp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @media screen and (max-width: 600px) {
      width: 100%;
      justify-content: left;
    }
  }

  .display-control-inp {
    width: 160px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.date-select-group {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 2px;

  &.date-select-group-invalid {
    border: 1px solid #ff4d4f;

    .ant-picker {
      border: 0px !important;
    }
  }

  .ant-picker {
    border-radius: 0 2px 2px 0;
    border-radius: 0px;
    border: 0px;

    .ant-picker-input {
      width: 130px;
      cursor: pointer;
      input {
        pointer-events: none;
      }
    }

    &:hover {
      box-shadow: 0 2px 1px -1px #40a9ff;
    }
  }

  @-webkit-keyframes focusBorderBottom {
    from,
    to {
      box-shadow: none;
      color: rgba(0, 0, 0, 0.85);
    }
    20% {
      box-shadow: 0 2px 1px -1px #40a9ff;
      color: #40a9ff;
    }
  }
  @keyframes focusBorderBottom {
    from,
    to {
      box-shadow: none;
      color: rgba(0, 0, 0, 0.85);
    }
    20% {
      box-shadow: 0 2px 1px -1px #40a9ff;
      color: #40a9ff;
    }
  }

  &.date-select-group--highlight {
    .ant-picker-input > input {
      -webkit-animation: focusBorderBottom 2s ease 1;
      animation: focusBorderBottom 2s ease 1;
    }
  }

  .ant-picker-focused {
    box-shadow: 0 2px 1px -1px #40a9ff;
  }
}

.date-select-group__prefix {
  display: flex;
  align-items: center;
  padding-left: 6px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.25);
}

.date-select-group__suffix {
  display: flex;
  align-items: center;
  padding-right: 6px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.25);

  &:hover {
    color: #40a9ff;
  }

  .auk-button.ant-btn {
    &:disabled {
      background-color: #fff;
    }
  }
}

.time-range__items {
  display: flex;
  flex-direction: column;
  width: 210px;
  margin-right: 0.5rem;
}

.time-range__nav {
  display: flex;
  align-items: center;
  margin-right: 0.5em;
}

.time-range__dates-select {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.time-range__config {
  display: flex;
  flex-direction: column;
  margin-right: 0.5em;
}

.time-range__hotkeys {
  display: flex;
  padding-bottom: 1px;
}

.time-range__span-res {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #fff;

  .ant-select {
    flex-shrink: 0;
  }
}

.time-range__span-res-item {
  flex-shrink: 0;

  .ant-select-selector {
    width: 90px !important;
    border: none !important;
    box-shadow: none !important;
    padding-right: 0px;

    .ant-select-selection-item {
      pointer-events: none;
    }

    &:hover {
      box-shadow: 0 2px 1px -1px #40a9ff !important;
    }
  }

  .ant-select-focused {
    box-shadow: 0 2px 1px -1px #40a9ff !important;
  }
}

.ant-select-item {
  padding: 5px 7px !important;
}

.time-range__span-res-divider {
  line-height: 22px;
  margin-right: 0.5em;
}

@media screen and (max-width: 1250px) {
  .control-panel {
    justify-content: space-between;

    .cp-info {
      display: none;
    }

    .current-time {
      display: none;
    }

    .control-panel-item {
      &.time-range {
        top: 23px;
        left: 75px;
        right: 0;
        padding: 0 8px;
      }
      &.status {
        top: 24px;
        text-align: left;
        flex-grow: 1;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .control-panel {
    overflow-x: auto;

    // .cp-settings {
    // flex-grow: 1;
    // }

    .control-panel-item {
      &.status {
        top: 0;
        text-align: left;

        .last-seen {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .control-panel {
    left: 50px;
    .control-panel-item {
      &.time-range {
        right: 0;
        left: 0;
      }
    }

    .cp-settings {
      display: none;
    }
  }
}

.gateway-status-indicator {
  background-color: #cccccc;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;

  &.active {
    background-color: #28a745;
  }
}

@media screen and (max-width: 575.98px) {
  .cp-main {
    flex-direction: column;
    width: 100%;
  }

  .time-range__nav {
    flex-grow: 1;
    margin: 0;
    margin-bottom: 0.5em;
  }

  .time-range__dates-select {
    flex-grow: 1;
  }

  .date-select-group {
    flex-grow: 1;

    .ant-picker {
      flex-grow: 1;
    }
  }

  .time-range__span-res-item {
    flex-grow: 1;
  }

  .time-range {
    flex-direction: column;
  }
  .time-range__items {
    width: 100%;
  }
}

.oee-switches {
  display: flex;
  flex-direction: column;

  .ant-switch {
    height: 24px;
    margin-bottom: 2px;
    width: 90px;
    border-radius: 4px;
    background-color: #808080;

    &.ant-switch-checked {
      background-color: #1890ff;
    }
  }

  .ant-switch-handle::before {
    border-radius: 4px;
  }
}
