.utilization--component {
  display: flex;
  flex-direction: column;

  .utilization--controls {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .row {
      margin: 0;
      width: 100%;
    }

    .form-group {
      margin: 0;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    .col,
    .col-10 {
      padding: 0;
    }
    .col-form-label {
      padding: 0;
      padding-right: 8px;
      justify-content: start !important;
      padding-left: 12px;
      font-weight: 400;
    }

    .ant-calender-picker-input-wrapper {
      flex-grow: 1;
    }
    .ant-calendar-picker-input {
      width: 100%;
      height: 30px;
      background: #fff;
      border-radius: 2px;
      border: 0px;
      border-bottom: 1px solid #ccc;
      flex-grow: 1;
      text-align: center;
      padding: 0;

      &:focus {
        border: 0px;
        border-bottom: 1px solid #268bff;
      }
    }

    .react-select-container {
      min-height: 0px;

      .react-select__control {
        height: 30px;
        border: 0px;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
      }

      .react-select__control--is-focused {
        // border: 0px;
        border-bottom: 1px solid #268bff;
        box-shadow: none;
      }

      .react-select__value-container {
        padding: 0px 6px;
      }

      .react-select__dropdown-indicator {
        padding: 0px 8px;
      }
    }

    .btn {
      height: 30px;
      border-radius: 2px;
    }
  }

  .utilization--body {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 2px;
    overflow: auto;
    max-width: calc(100vw - 78px);

    .blank {
      height: 30px;
      position: fixed;
      width: 280px;
      z-index: 3;
      background: #fff;
    }

    .chart-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;

      .heatmap--y-axis {
        position: sticky;
        left: 0;
        z-index: 2;
        width: 280px;
        padding-left: 15px;
        flex-shrink: 0;
        overflow-x: overlay;
        height: max-content;
        background: #fff;
        z-index: 2;
        padding-top: 30px;
        padding-bottom: 10px;
      }

      .heatmap--diagram {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
}
