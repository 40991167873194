.widget__body--with-loaders {
  width: 100%;
  height: calc(100% - 15px) !important;
  position: relative;
}

.widget__loaders {
  display: flex;
  width: 100%;
  height: 15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
}
