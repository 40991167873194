.pareto-analysis {
  .counter {
    // width: 150px;
    min-width: 120px;
  }

  .counter-value {
    width: max-content;
  }
}

.pareto-modal {
  &.ant-modal .ant-modal-body {
    overflow: hidden;
    padding: 0px;
  }
}
