.tree-table-row {
  white-space: nowrap;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  overflow: hidden;
  flex-wrap: nowrap;

  .tree-table-cell {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  }
}
