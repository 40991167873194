$bcolor: lightgray;

.skumanagement {
  padding: 1em;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;

  .ant-pagination {
    .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .edit {
    border: none;
    background-color: transparent;
  }

  .btn {
    font-size: 12px;
    font-weight: 500;
    border-radius: 1px;
    margin: 0 5px;
  }
  .new {
    color: white;
    background-color: rgb(32, 115, 255);
  }
  .csv {
    color: white;
    background-color: rgb(95, 161, 55);
  }
  .template {
    color: white;
    background-color: rgb(43, 70, 26);
  }

  button:focus {
    outline: 0;
  }
  button:hover {
    cursor: pointer;
  }
}

.hoverable:hover {
  opacity: 0.79;
  transition: 0.15s;
  cursor: pointer;
}
