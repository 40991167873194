.block-row {
    display: flex;
    flex-direction: row;

    .oee-dials {
        width: 30%;
        flex-shrink: 0;
    }

    .oee-dials__lapq {
        height: 60% !important;
    }

    @media screen and (max-width: 990.98px) {
        .oee-dials {
            min-width: 310px;
        }
    }

    @media screen and (max-width: 575.98px) {
        flex-direction: column;

        .oee-dials {
            height: 90px;
            min-width: 0px;
            width: 100%;
            flex-shrink: 0;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
