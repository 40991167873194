.onactive__container {
  cursor: text;

  .onactive__input-wrapper {
    display: flex;
    flex-direction: row;

    .onactive__input {
      min-width: 33px;
      z-index: 2;
    }
    .onactive__input-save {
      cursor: pointer;
      flex-shrink: 0;
      color: #fafafa;
      height: 100%;
      background: #268bff;
      padding: 0px 6px;
      z-index: 2;
    }
  }

  &.disabled {
    cursor: default;
  }
}
