.GridRow {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.LeftSideGridContainer {
  flex: 0 0 75px;
  z-index: 10;
}

.LeftSideGrid {
  // https://jsfiddle.net/Asif92/aqqn6csn/2/
  // &::-webkit-scrollbar-track:vertical {
  //   display: none;
  // }
  // &::-webkit-scrollbar-thumb:vertical {
  //   display: none;
  // }
  background-color: #fff;
}
.HeaderGrid {
  width: 100%;
  overflow-x: hidden !important;
  background-color: #fff;
}
.BodyGrid {
  width: 100%;
}

.evenRow {
}
.oddRow {
  background-color: rgba(0, 0, 0, 0.1);
}

.cell,
.headerCell,
.leftCell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  // padding: 0 0.5em;
}
.headerCell,
.leftCell {
  font-weight: 400;
}

.cell {
  &.highlightable {
    &:hover {
      font-weight: 600;
      border: 2px solid #616161 !important;
      cursor: crosshair;
    }
  }
}

#x-indicator,
#y-indicator {
  border: 0.5px solid #616161;
  pointer-events: none;
  z-index: 20;
}
