.labels-search {
  padding: 1em;
  height: 100%;
  width: 100%;
  overflow: auto;

  .content-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .collapsible--header {
    right: 0;
  }

  .collapsible--header {
    &.collapsed {
      .collapsible--caret {
        position: absolute;
        right: 0;
      }
    }
  }

  .vtable-cell {
    font-size: 12px;
  }

  .date-select__options {
    padding-right: 0.5em;
  }
}
