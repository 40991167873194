.users-page {
    .ant-tabs-content {
        height: 100%;
        width: 100%;
        display: flex;
    }

    .users-tab-content {
        height: 100%;
        overflow: scroll;
    }
    
    .groups-tab-content, .invites-tab-content {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: scroll;
    }
}