.entity-config {
  padding: 1em;
  width: 100%;

  .panel {
    max-width: 100%;
    max-height: 100%;
  }

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content {
    display: flex;
    justify-content: center;
  }

  .ant-tabs-content-top {
    height: 100%;
    padding: 1rem;
    overflow: auto;
  }

  .ant-form-item.no-style {
    .ant-form-item-label {
      label {
        border: none;
        background: #fff;
      }
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-checkbox {
      top: 0;
    }
  }
}

.th-color-input {
  position: relative;
}

.th-input__label {
  background-color: #ddd;
  display: flex;
  justify-content: center;
}

.color-input {
  width: 140px;
  height: 140px;
  cursor: pointer;
}

.ant-input-affix-wrapper.color-input__th {
  position: absolute;
  width: 80px;
  left: 30px;
  top: 58px;
}

.oee-th-gradient {
  border-radius: 2px;
  width: 452px;
  height: 20px;
  opacity: 0.9;
}
