$bcolor: lightgray;

.usersmanagement {
  padding: 1em;
  height: 100%;
  width: 100%;
  overflow: auto;

  .ant-pagination {
    .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // .row {
  //   margin: 0;
  // }

  .tab-content {
    // min-height: 100px;
    min-height: 75vh;
    border-left: 1px solid $bcolor;
    border-bottom: 1px solid $bcolor;
    border-right: 1px solid $bcolor;
    background-color: white;
    padding: 1em;
    position: relative;

    .ReactTable {
      // margin-top: 0px;

      &.rt-users {
        border: 0;
        margin-bottom: 20px;
      }

      .rt-tbody {
        .users-select {
          border: 0;
          font-weight: 300;
          color: #7a7a7a;
        }

        .user-delete {
          color: red;
          display: flex;
          justify-content: center;
          cursor: pointer;
        }

        :focus {
          outline: 0;
        }

        .role-btn {
          margin: 0px;
          width: 100px;
        }

        .users-delete-margin {
          margin-left: 10px;
        }

        .users-cursive {
          font-style: italic;
          color: rgb(201, 201, 201);
        }

        svg.fa-edit {
          color: #d8d8d8;
          cursor: pointer;
        }

        svg.fa-edit:hover {
          color: rgb(185, 185, 185);
        }

        .gr-list-users {
          display: block;
          white-space: pre-wrap;

          .gr-list-users-inline {
            display: inline-block;
          }

          .moreLess {
            display: inline-block;
            font-style: normal;
            color: rgba(150, 150, 150, 0.575);
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            margin-left: 10px;
            border-radius: 10px;
            padding-left: 5px;
            padding-right: 5px;
            background-color: rgba(150, 150, 150, 0.103);
            padding-bottom: 3px;

            &:hover {
              background-color: rgba(150, 150, 150, 0.178);
            }
          }
        }
      }
    }

    .b-save {
      margin-left: 0;
    }
  }

  .nav-tabs {
    border-bottom: 1px solid $bcolor;

    .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      cursor: pointer;
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      color: #495057;
      background-color: #fff;
      border-color: $bcolor $bcolor white;
    }
  }

  // .col {
  //   -webkit-box-flex: 0;
  //   -ms-flex: 0 0 90%;
  //   flex: 0 0 90%;
  //   max-width: 90%;

  // }
}

.container {
  padding: 0;
}

.btn-add-users {
  position: relative;
  width: 2em;
  height: 2em;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #aaa;
    transition: transform 0.25s ease-out;
  }

  /* Vertical line */
  &:before {
    top: 0.5em;
    left: 1em;
    width: 2px;
    height: 1em;
    margin-left: -1px;
  }

  /* horizontal line */
  &:after {
    top: 1em;
    left: 0.5em;
    width: 1em;
    height: 2px;
    margin-top: -1px;
  }

  &:hover {
    cursor: pointer;

    &:before {
      background-color: #616161;
    }

    &:after {
      background-color: #616161;
    }
  }
}

.invitees {
  max-height: 200px;
  overflow: auto;
}

.modal--user-groups {
  .panel-body {
    height: 30vw;
  }
}
