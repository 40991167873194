.legend {
  position: relative;
  top: auto;
  // left: 30px;
  // width: calc(100% - 30px);
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 990.98px) {
    // position: relative;
    // top: auto;
    // left: 30px;
    width: 100%;
    // display: flex;
    // flex-wrap: wrap;
  }

  .legend-keys {
    font-size: 12px;
    margin-bottom: 2px;
    padding-left: 6px;
    height: 16px;
    line-height: 1;
    overflow: hidden;
    width: 25%;

    .legend-color {
      width: 15px;
      height: 15px;
      border-radius: 1px;
      display: inline-block;
    }

    span {
      margin-left: 10px;
      margin-top: 1px;
      vertical-align: text-top;
    }
  }

  &.vertical {
    position: absolute;
    bottom: 20px;
    right: 30px;
    left: inherit;
    width: 200px;

    .legend-keys {
      width: 100%;
    }
  }
}
