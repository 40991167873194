.autosize-text-wrapper {
  height: 100%;
  width: 100%;
}

.autosize-text {
  display: -webkit-box;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 400;
}
