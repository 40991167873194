$COMPOSITE_BACKGROUND: rgba(0, 28, 85, 1);
$COMPOSITE_COLOR: #fff;

.blocks-tree {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.blocks-tree__body {
  flex-grow: 1;

  .ant-collapse {
    flex-grow: 1;

    .ant-collapse-item {
      .ant-collapse-header {
        padding-right: 0px !important;
        padding-top: 3px !important;
        padding-bottom: 3px !important;
      }

      .ant-collapse-content-box {
        padding: 0px !important;
      }

      &:not(.ant-collapse-item-active) .ant-collapse-header {
        border: 0px;
      }
    }
  }
}

.blocks-tree__nodes {
  width: 100%;
}

.blocks-tree__node {
  display: flex;
  position: relative;
  flex-grow: 1;
  padding-top: 3px;
  padding-bottom: 3px;
}

.blocks-tree__node__composite {
  border-left: 1px dotted #bbb;
}

.blocks-tree__node__composite__header {
  background: $COMPOSITE_BACKGROUND;

  .blocks-tree__node__weightage {
    background: none;
    color: #fff;
    border: 1px dotted #fff;
  }

  .blocks-tree__node__label {
    background: none;
    color: #fff;
  }

  .block-operation {
    border: 1px dotted #fff;
  }

  .block-operation__label {
    background: none;
    color: #fff;
  }
}

.blocks-tree__node__header {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 5px;
  border: 1px dotted lightgray;
  margin-right: 6px;
  border-radius: 2px;
}

// .blocks-tree__node__operator {
//   position: relative;
//   display: flex;
//   width: 40px;
//   height: 28px;
//   border-radius: 3px;
//   flex-shrink: 0;
//   font-size: 12px;
// }

// .blocks-tree__node__operator-name {
//   text-transform: uppercase;
//   border-radius: 2px;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.blocks-tree__node__label {
  flex-grow: 1;
  height: 28px;
  line-height: 28px;
  padding-left: 6px;
  color: #000;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}

.blocks-tree__node__label__value {
  width: 100%;
  position: absolute;
}

.blocks-tree__node__weightage {
  width: 40px;
  height: 28px;
  border: 1px solid #ddd;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blocks-tree__node__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-weight: 800;
  width: 12px;
  height: 12px;
  font-size: 10px;
  border-radius: 50%;
  color: #fafafa;
  top: -4px;
  right: -4px;
  z-index: 2;
}
