.auk-calendar {
  width: 100%;
  height: 100%;
  position: relative;
}

.auk-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 0 1em;
  width: 100%;
}
