.device-scan {
  width: 60%;
  max-width: 600px;
}

.device-scan__icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
