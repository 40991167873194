.color-picker {
  flex-grow: 1;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  height: 32px;

  &:hover {
    border-color: #b3b3b3;
  }

  .compact-picker {
    margin: 10px;
  }
}
