.label-counters {
  position: absolute;
  display: flex;
  right: 1em;
  top: 1em;

  @media screen and (max-width: 575.98px) {
    position: relative;
    justify-content: flex-end;
    right: inherit;
    top: inherit;
    margin: 1em 0;
  }
}
