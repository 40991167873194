.percent-bar-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .percent-bar__label {
    width: 20%;
    height: 100%;
  }

  .percent-bar {
    position: relative;
    flex-grow: 1;
    height: 100%;
    background: #ddd;
    border-radius: 2px;
    overflow: hidden;

    .percent-bar__value {
      max-width: 100%;
      height: 100%;
      z-index: 2;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }

  .percent-bar__value-label {
    width: 30%;
    height: 100%;

    .autosize-text {
      text-align: right;
    }
  }
}
