.arrow {
  // border: 1px solid #000;
  pointer-events: none;
  position: absolute;
  z-index: 1;

  .node {
    fill: antiquewhite;
    stroke: #000;
    cursor: pointer;
  }

  .block {
    fill: antiquewhite;
    stroke: #000;
    cursor: pointer;
  }

  .overlay-line {
    pointer-events: stroke;
    stroke: transparent; //bisque;
    stroke-width: 15px;
    stroke-linecap: round;
    fill: none;
    cursor: pointer;
    
    &.highlight {
      stroke: rgba(245, 245, 220, 0.5);
    }
  }
  .line {
    pointer-events: none;
    stroke: #8d8d8d; // red;
    stroke-width: 2px;
    fill: none;

    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    // animation: dash 10s linear infinite;
  }

  .point {
    pointer-events: all;
    fill: #fff;
    stroke-width: 1px;
    cursor: pointer;

    &.line-point {
      fill: #fff;
      stroke: dimgray;
    }
    &.start {
      stroke: red;
    }
    &.end {
      stroke: green;
    }
  }
  
  @keyframes dash {
    from {
      stroke-dashoffset: 30;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
}