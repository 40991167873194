.basic-view-panel {
  background-color: #ececec;
  padding: 5px 10px 10px 10px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .basic-view-panel-header {
    margin-bottom: 10px;

    p {
      margin: 0;
    }

    .header-tag {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 20px;
      margin-right: 10px;
      letter-spacing: 2px;
    }

    .header-total {
      font-size: 20px;
      font-weight: 200;
    }

    .input-group-append {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 0 6px;
      flex-grow: 1;

      .form-control {
        border: 0px;
        flex-grow: 1;
        padding: 0px;
      }

      .input-group-append__icon {
        flex-shrink: 0;
        height: 100%;
        display: flex;
        align-items: center;

        .fas,
        .fa {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .basic-view-panel-content {
    overflow: auto;
    max-height: 100%;

    .panel-item {
      width: 48%;
      padding: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      background-color: #ffffff;
      height: 50px;
      border-left: 10px solid #616161;
      color: #222;
      font-weight: 400;
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
      border-radius: 3px;

      &.is-disabled {
        cursor: not-allowed;
        opacity: 0.3;
        box-shadow: none;
      }
    }
  }
}
