.block-title {
  // z-index: 3;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .block-title__image {
    width: 50px;
    height: 50px;
    border-radius: 2px;
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
    color: #616161;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .block-title__image-placeholder {
    color: rgb(0, 28, 85);
    text-transform: uppercase;
    font-weight: 500;
  }

  .auk-blocks-select {
    max-width: 300px;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  @media screen and (max-width: 575.98px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;

    .block-title__image {
      display: none;
    }

    .auk-blocks-select {
      margin: 0px;
    }
  }


}
