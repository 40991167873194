.main-logo {
  text-align: center;

  img {
    height: 8em;
  }
}

.register-page {
  .panel {
    width: 60vw;
  }
}

.container-fluid {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;

  .container {
    display: table-cell;
    vertical-align: middle;

    .panel {
      transition: all 0.4s  linear;

      &.-login {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.145);
      }
    }
  }
}

.form-check1-label {
  color: #fd4848;
}
