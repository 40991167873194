.oee-scale--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  .oee-scale {
    margin: 0 auto;
    background-image: linear-gradient(
      to right,
      rgba(204, 51, 51, 0.8),
      rgba(255, 179, 0, 0.8),
      rgba(64, 191, 106, 0.8)
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.75);
    height: 16px;
    width: 90%;

    label {
      z-index: 2;
      margin: 2px 8px;
      font-weight: 500;
      font-size: 11px;
      color: #222;
    }
  }
}
