.asset-shifts {
  padding: 1em;
}

.asset-shifts__body {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  margin-bottom: 1em;
}

.auk-calendar-shift-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  padding: 12px;
}

.auk-calendar-shift-card__label {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}
