.order-select-all {
  float: left;
  padding: 0;
  border-radius: 3px;
  width: 100%;
  overflow-y: auto;
}

.order-select-single {
  position: relative;
  list-style: none;
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  cursor: move;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
