.day {
  width: 23px;
  line-height: 23px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  background: #e6e3e3;
  display: inline-block;
  margin-right: 6px;

  &.active {
    background: #1a73e8;
    color: white;
  }
}