.counter {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 2px 5px;
    border: 2px solid #cccccc;
    border-radius: 2px;
    position: relative;
    justify-content: center;
    margin-left: 10px;
    font-size: 30px;
}

.counter-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.counter-value {
    font-size: 20px;
    letter-spacing: 1px;
    justify-content: flex-end;
    width: 100%;
}

.counter-header__label {
    font-size: 13px;
}

.counter-icons {
    display: flex;
    align-items: center;
    margin-left: 0.5em;
    color: #cccccc;
}

.counter-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blink {
    animation: repeat-color 1s forwards;
    -webkit-animation: repeat-color 1s forwards;
    -moz-animation: repeat-color 1s forwards;
    -o-animation: repeat-color 1s forwards;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
}


@keyframes repeat-color {
    0% {
      color: #cccccc;
    }
    50% {
      color: rgb(243, 49, 49);
    }
    100% {
      color: #cccccc;
    }
  }

  @-webkit-keyframes repeat-color {
    0% {
      color: #cccccc;
    }
    50% {
      color: rgb(243, 49, 49);
    }
    100% {
      color: #cccccc;
    }
  }

  @-moz-keyframes repeat-color {
    0% {
      color: #cccccc;
    }
    50% {
      color: rgb(243, 49, 49);
    }
    100% {
      color: #cccccc;
    }
  }

  @-o-keyframes repeat-color {
    0% {
      color: #cccccc;
    }
    50% {
      color: rgb(243, 49, 49);
    }
    100% {
      color: #cccccc;
    }
  }

.counter-header {
    display: flex;
}