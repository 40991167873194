.oee-dials {
  display: flex;

  &.display-column {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;

    .oee-dials__effective {
      text-align: center;
      width: 80%;
      height: 60%;
    }

    .oee-dials__lapq {
      width: 90%;
      height: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 0.25em;
    }
  }

  .percent-bar-container {
    margin-bottom: 0.25em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.display-row {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .oee-dials__lapq {
      width: 66%;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .percent-bar-container {
        height: 20%;
        width: 90%;
      }
    }

    .oee-dials__effective {
      flex-grow: 1;
      height: 100%;
    }
  }
}
