.impact-chart {
    border-radius: 4px;
    border: 1px solid #C1C7CE;

    .rv-xy-plot__inner {
        background: #fff;
    }
}

.impact-chart__bar-series rect {
    rx: 4px;
    ry: 4px;
    stroke-width: 1px;

    &:nth-child(even) {
        cursor: pointer;    
    }
}

.impact-chart__x-tick {
    fill: #1E1E1E;
    transform: translate(0, 10px);
}

.impact-chart__x-tick--with-popover {
    cursor: pointer;
}

.impact-chart__x-tick__title {
    font-weight: 500;
    font-size: 14px;
}

.impact-chart__x-tick__subtext {
    font-weight: 400;
    font-size: 14px;
}

.impact-chart__label-point {
    font-weight: 500;
    font-size: 14px;

    text:last-child {
        fill: #fff;
    }
}

.impact-chart__label-point__title {
    font-weight: 700;
    font-size: 22px;
}

.impact-chart__label-point__subtext {
    font-weight: 400;
    font-size: 14px;
}

.impact-chart__footnote {
    position: absolute;
    width: 100%;
    font-weight: 200;
    font-size: 12px;
    color: #71787E;
    display: flex;
    align-items: center;
}

.impact-chart__header {
    position: absolute;
    top: 17px;
    width: 100%;
    font-weight: 200;
    font-size: 12px;
    color: #DDE3EA;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .impact-chart__x-tick__title, .impact-chart__x-tick__subtext {
        font-weight: 400;
        font-size: 10px;
    }

    .impact-chart__label-point {
        font-size: 12px;
    }

    .impact-chart__label-point__title {
        font-size: 12px;
    }

    .impact-chart__label-point__subtext {
        font-weight: 500;
        font-size: 10px;
    }
}

@media screen and (max-width: 600px) {
    .impact-chart__x-tick__title, .impact-chart__x-tick__subtext {
        font-size: 8px;
        letter-spacing: 0.4px;
    }

    .impact-chart__label-point {
        font-size: 10px;
    }

    .impact-chart__label-point__title {
        font-size: 10px;
    }

    .impact-chart__label-point__subtext {
        font-size: 8px;
    }
}