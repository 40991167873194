.date-select__options {
  border-bottom: 1px solid #ddd;
  background-color: #fefefe;
  display: flex;
  width: 100%;
  padding: 1em;

  .react-select__control {
    min-height: auto;
    height: 30px;
  }

  .ant-select {
    width: 100%;
  }

  .ant-picker-range {
    width: 100%;

    .ant-picker-suffix {
      display: flex;
    }

    .ant-picker-separator {
      display: flex;
    }
  }
}

.date-select__option {
  width: 50%;
  padding-right: 1.5em;
}

.date-select__load {
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
}
