.objectives-empty-placeholder {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .empty-placeholder-icon {
    font-size: 64px;
    color: #C6E7FF;
  }
}
