.ant-tooltip {
  .ant-tooltip-inner {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    min-height: 0px;
    min-width: 0px;
  }
}
