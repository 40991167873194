.tree-table-header {
  width: 100%;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;

  .tree-table-cell {
    font-size: 0.875rem;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
  }
}
