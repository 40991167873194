.st-management {
  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content-top {
    height: 100%;
  }
}

.st-tab-content-container {
  display: flex;
  flex-direction: column;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  .ant-select-selector {
    min-height: 100%;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .LeftSideGrid {
    // background-color: #fafafa;
    overflow: auto !important;

    &::-webkit-scrollbar-track:vertical {
      display: none;
    }
    &::-webkit-scrollbar-thumb:vertical {
      display: none;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: initial !important;
    }
  }

  .HeaderGrid {
    &::-webkit-scrollbar-track:vertical {
      display: none;
    }
    &::-webkit-scrollbar-thumb:vertical {
      display: none;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: auto !important;
      display: flex;
      justify-content: flex-start;
      flex-direction: column-reverse;
    }
  }

  .leftCell {
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;

    .axis-cell-content {
      display: -webkit-box;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .headerCell {
    display: flex;
    justify-content: flex-start;
    border: 1px solid #ddd;
    border-top: 0px;
  }

  .cell {
    border: 1px solid #ddd;

    .checkbox-container {
      width: 100%;
      height: 100%;

      &.highlightable {
        &:hover {
          outline: 2px solid #616161;
        }
      }
    }
  }

  .standard-time-oee-table {
    height: 100%;
    width: 100%;
  }

  .disabled {
    // background-color: gray;
    opacity: 0.4;
    // background: rgba(0,0,0, 0.2);
    // color: white;
  }

  .enabled:hover {
    cursor: pointer;
  }
}

.st-list-item {
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #eee;
  &:hover {
    background-color: #f5f5f5;
  }

  .ant-input-group-addon {
    background-color: #1890ff;
    color: #fff;
    cursor: pointer;
  }
}

.st-list-item__col {
  display: flex;
  align-items: center;
  padding: 6px;
}

.ant-select.st-filter {
  width: 30%;
  margin-right: 1em;
}

// .auk-input__submit {
//   border: 0px;
//   cursor: pointer;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: none;
//   color: #1890FF;
//   &:hover {
//     color: #40a9ff;
//   }
// }

.header-asset-oee {
  display: flex;
  flex-direction: column;
  // background-color: #fafafa;
  width: 100%;
  background: rgba(0,0,0, 0.1);
  // color: white;
}

.header-asset-oee--absolute {
  position: absolute;
  width: 180px;
  height: 120px;

  .header-asset-oee__item {
    padding-left: 0.5em;
    padding-right: 0.5em;
    justify-content: start;
  }
}

.header-asset-oee__title {
  height: 60px;
  width: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-bottom: 1px solid #ddd;
  min-width: 0px;

  p {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.header-asset-oee__item {
  position: relative;
  display: flex;
  height: 30px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ddd;
  justify-content: center;
}
