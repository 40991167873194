.asset-management {
  &.spa-wrapper {
    .ant-layout-sider-children {
      border: 1px solid #ddd;
    }
  }

  .asset-form__body {
    flex-direction: column;
    overflow: auto;
  }

  .asset-form__image {
    margin: 0 auto;
  }

  code {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    color: #616161;
    border-radius: 2px;
    font-size: 13px;
  }
}
