.channel-config {
  .ant-collapse-item:not(.ant-collapse-item-active) .ant-collapse-header {
    border: 0px;
  }

  .ant-input-group-addon {
    min-width: 60px;
    text-align: left;
  }

  .ant-collapse-header {
    cursor: default !important;
  }
}
