.loaders-horizontal {
  display: flex;
  flex-direction: row;
  width: 30px;

  .circle {
    margin: 0px 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #ddd;
  }
}