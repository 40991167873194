.data-inputs-select {
    width: 80%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-select {
        width: 100%;
    }
}