.day-calendar {
  padding: 16px;
  height: 100%;

  .day-calendar-actions {
    padding-bottom: 8px;

    .calendar-actions-year {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .calendar-actions-item {
      width: 40px;
      height: 40px;
    }
  }

  .day-calendar-buttons {
    .calendar-buttons-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & > * {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px !important;
        height: 40px !important;
        margin: 4px 0 !important;
      }
    }
  }
}
