@import '../Calendar/index.scss';
$borderStroke: 1px solid #eee;
$gridPaddingTop: 10px;

.auk-calendar-week {
  width: 100%;
  height: 100%;
  display: flex;
}

.auk-calendar-week__nav {
  width: 120px;
  padding: 1em;
  position: absolute;
  right: 0px;
  cursor: pointer;
  z-index: 2;

  &:first-of-type {
    left: 0;
  }

  &:hover {
    color: #40a9ff !important;
  }
}

.auk-calendar-week__item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.auk-calendar-week__item--bold {
  font-family: Inter;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.auk-calendar-week__nav-arrow {
  font-size: 24px;
  margin-bottom: 0.5em;
}

.auk-calendar-week-days__header {
  height: 50px;
  display: flex;
  width: 100%;
  padding: 0 150px;
}

.auk-calendar-week-days {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.auk-calendar-week-days__header-cell {
  position: sticky;
  top: 0;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
}

.auk-calendar-week-days__header-cell--active {
  color: #dc3545;
  font-weight: 500;
}

.auk-calendar-week-days__header-cell--blur {
  opacity: 0.6;
}

.auk-calendar-week-days__grid {
  overflow-y: scroll;
  max-height: 100%;
  width: 100%;
  display: flex;
  padding: 0 140px 40px 150px;
  position: relative;
}

.auk-calendar-week-days__grid
  > .auk-calendar-week-days__grid-column
  ~ .auk-calendar-week-days__grid-column {
  border-left: 0px;
}

.auk-calendar-week-days__grid-column {
  position: relative;
  padding-top: $gridPaddingTop;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  border-right: $borderStroke;
  border-left: $borderStroke;
}

.auk-calendar-week-days__grid-column:first-of-type {
  border-left: $borderStroke;
}

.auk-calendar-week-axis {
  position: absolute;
  width: calc(100% - 270px); // subtract padding + 20px
  padding-top: $gridPaddingTop;
  left: 140px;
}

.auk-calendar-week-axis-item {
  position: relative;
  width: 100%;
  &:first-of-type {
    border-top: $borderStroke;
  }
}

.auk-calendar-week-axis-label {
  position: absolute;
  top: -10px;
  left: -40px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
}

.auk-calendar-week-card {
  width: 100%;
  position: absolute;
  padding: 1px;
  overflow: hidden;
  transition: all 0.2s linear;
  cursor: pointer;
  background: white;
}

.auk-calendar-week-card--hovered {
  z-index: 999 !important;

  .auk-calendar-week-card__inner {
    background-color: #40a9ff !important;
  }
}

.auk-calendar-week-card--fade {
  .auk-calendar-week-card__inner {
    // opacity: 0.6;
  }
}

.auk-calendar-week-card__inner {
  height: 100%;
  width: 100%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 12px;
  overflow: hidden;
  // box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.auk-calendar-week__current_indicator {
  position: absolute;
  width: calc(100% - 270px);
  height: 2px;
  background: #dc3545;
  z-index: 99;
  left: 140px;
}
