.banner {
  width: 100%;
  padding: 6px;
  font-size: 11px;
  text-align: center;
  position: relative;
}

.banner-close {
  position: absolute;
  right: 12px;
  top: 4px;
}

.banner.banner--with-modal {
  cursor: pointer;
}
