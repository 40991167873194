.auk-form-modal {
  &.submitted {
    .fields {
      .is-invalid {
        border-color: #dc3545;

        &:focus {
          border-color: #40a9ff;
        }

        &:hover {
          border-color: #40a9ff;
        }

        .ant-select-selector {
          border: 1px solid #dc3545;

          &:focus {
            border-color: #40a9ff !important;
          }
        }
      }
    }
  }
}
