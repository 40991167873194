.auk-input-with-submit--disabled {
    .auk-input__submit {
        pointer-events: none;
        color: #bbb !important;
    }
}

.auk-input__submit {
    border: 0px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: #1890FF;
    &:hover {
        color: #40a9ff;
    }
}

