.system-downtime {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;

  .markdown-body {
    width: 60%;
  }

  img {
    width: 100%;
  }
}
