.auk-modalpanel {
  // position: relative;

  .auk-modalpanel__underlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    background-color: black;
    z-index: 3;
  }

  .auk-modalpanel__main {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 60vw;
    top: 50%;
    z-index: 4;

    @media screen and (max-width: 990.98px) {
      // margin-top: 0;
      min-width: 80vw;
    }
  }
}
