.v1-dashboard {
  padding: 0 !important;
}

.dashboard__grid-cell {
  height: 100%;
  width: 100%;
  background: rgb(245, 245, 245);

  .dashboard-cell-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    opacity: 0;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
      background-color: #fcf2e5;
    }

    p {
      margin: 0;
      opacity: 0.3;
    }
  }
}

.dashboard-control {
  position: fixed;
  right: 10px;
  background: #fff;
  z-index: 2;
  height: 35px;
  display: flex;

  // .control-buttons {
  //   display: flex;
  //   justify-content: flex-end;
  //   padding: 6px;

  //   .auk-button--round {
  //     width: 42px;
  //     height: 42px;
  //     margin: 0 5px;
  //   }
  // }

  // .subcontrols {
  //   font-size: 10px;
  //   font-weight: 600;

  //   .edit-row {
  //     height: 30px;
  //     display: flex;
  //     margin: 3px;
  //     padding: 0px 10px;
  //     border-radius: 2px;
  //     cursor: pointer;
  //     align-items: center;
  //     background: #ddd;

  //     svg {
  //       margin-right: 3px;
  //       margin-bottom: -1px;
  //     }

  //     &:hover {
  //       font-weight: 600;
  //       border: 2px solid #616161;
  //     }
  //   }

  //   .view-row {
  //     height: 30px;
  //     display: flex;
  //     margin: 3px;
  //     justify-content: space-between;
  //     align-items: center;
  //   }
  // }
}
